import {getConfig} from "../constants/config";
import axios from "axios";
import {getActiveAccessToken} from "../../auth/KeycloakAuthProvider";

const config = getConfig();
export const baseURL = config.protocol + "://" + config.host + ":" + config.port + config.basePath;
export const wsBaseURL = config.wsProtocol + "://" + config.host + ":" + config.port + config.wsBasePath;

const http = axios.create({baseURL});

http.interceptors.request.use(async (config) => {
      return new Promise((resolve) => {
        getActiveAccessToken().then((accessToken) => {
          config.headers.Authorization = `Bearer ${accessToken}`;
          resolve(config);
        }).catch(() => {
          //Send without any token - this makes sense for requests that are possible without authentication
          resolve(config);
        });
      });
    },
    error => Promise.reject(error)
);

export default http;