import "./RideProviderSidebar.scss";
import i18n from "../core/constants/i18n";
import {Button, BUTTON_EMPHASIS, BUTTON_VARIANTS, ContentContainer, HeadlineText, SectionError} from "@odm/ui";
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {baseUrlAdminRideProvider, routeParameter} from "../core/constants/routes";
import {useSelector} from "react-redux";
import {getRideProvider, selectRideProviderById} from "../core/redux/slices/rideProviderSlice";
import RideOrdererAddDialog from "./RideOrdererAddDialog";
import {useEffect, useState} from "react";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import {getRideOrderers, selectUndissolvedRideOrderersByRideProviderId} from "../core/redux/slices/rideOrdererSlice";

const RideProviderSidebar = () => {
  const {[routeParameter.rideProviderId]: rideProviderId} = useParams()
  const rideProvider = useSelector((state) => selectRideProviderById(state, rideProviderId))
  const rideOrderers = useSelector((state) => selectUndissolvedRideOrderersByRideProviderId(state, rideProviderId))
  const [addRideOrdererOpen, setAddRideOdererOpen] = useState(false);
  const navigate = useNavigate()

  const [dispatchGetRideOrderers, rideOrderersError] = useDispatchAsync(getRideOrderers)
  const [dispatchGetRideProvider] = useDispatchAsync(getRideProvider)

  useEffect(() => {
    dispatchGetRideProvider({rideProviderId})
    dispatchGetRideOrderers({rideProviderId})
  }, [])

  return (
      <nav className={"ride-provider-sidebar"}>
        <ContentContainer>
          <ul>
            <li>
              <HeadlineText element={"span"}>
                {rideProvider?.companyName}
              </HeadlineText>

              <NavLink exact to={`${baseUrlAdminRideProvider}/${rideProviderId}/`} id="nav-link_organisation">
                {i18n.t("organisation")}
              </NavLink>

              <NavLink exact to={`${baseUrlAdminRideProvider}/${rideProviderId}/page-info`} id="nav-link_page-customization">
                {i18n.t("page customization")}
              </NavLink>
            </li>
            <li>
              <HeadlineText element={"span"}>{i18n.t("ride orderer configuration")}</HeadlineText>
                <ul className={'ride-orderer-list'}>
                  {rideOrderers.map((rideOrderer, index) =>
                    <li key={rideOrderer.id}>
                      <NavLink
                        id={"nav-link_ride-orderer-" + index}
                        exact
                        to={`${baseUrlAdminRideProvider}/${rideProviderId}/${rideOrderer.id}`}
                      >
                          {rideOrderer.name}
                      </NavLink>
                    </li>
                  )}
                </ul>
            </li>
            <li className={'add-ride-orderer'}>
              <Button
                title={i18n.t("add ride orderer")}
                id="button_add-ride-orderer"
                variant={BUTTON_VARIANTS.add}
                emphasis={BUTTON_EMPHASIS.minimal}
                onClick={() => setAddRideOdererOpen(true)}
              />
            </li>
            <li>
                {rideOrderersError ? (<SectionError errorMessage={i18n.t("get rideOrderers rejected")}/>) : null }
            </li>
          </ul>
        </ContentContainer>
        <RideOrdererAddDialog
          isOpen={addRideOrdererOpen}
          rideProviderId={rideProviderId}
          onClose={(rideOrderer) => {
            if (rideOrderer) {
              navigate(`${baseUrlAdminRideProvider}/${rideProviderId}/${rideOrderer.id}`)
            }
            setAddRideOdererOpen(false)
          }}
        />
      </nav>
  )
}

export default RideProviderSidebar;