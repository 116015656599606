import {Field, getIn, useFormikContext} from "formik";
import {CONTROL_APPEARANCE, ControlDecorator, Input} from "@odm/ui";
import {MdPersonOutline} from "react-icons/md";
import i18n from "../core/constants/i18n";

const fieldNames = {
  firstName: "firstName",
  lastName: "lastName",
}

const RideOrderPassengerFormSection = () => {
  const {isSubmitting, errors, touched} = useFormikContext()

  return (
      <>
        <ControlDecorator icon={<MdPersonOutline/>}
                          error={getIn(touched, fieldNames.firstName) && getIn(errors, fieldNames.firstName)}
        >
          <Field placeholder={i18n.t("firstname")}
                 id="input_firstname"
                 as={Input}
                 name={fieldNames.firstName}
                 disabled={isSubmitting}
                 controlAppearance={CONTROL_APPEARANCE.filled}
          />
        </ControlDecorator>
        <ControlDecorator icon={true}
                          error={getIn(touched, fieldNames.lastName) && getIn(errors, fieldNames.lastName)}
        >
          <Field placeholder={i18n.t("lastname")}
                 id="input_lastname"
                 name={fieldNames.lastName}
                 as={Input}
                 disabled={isSubmitting}
                 controlAppearance={CONTROL_APPEARANCE.filled}
          />
        </ControlDecorator>
      </>
  )
}

export default RideOrderPassengerFormSection