import React from "react";
import {ButtonGroup, PageHeader} from "@odm/ui";
import {WhitePortal} from "react-native-portal";
import portalNames from "../core/constants/portalNames";
import {routeParameter} from "../core/constants/routes";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectRideProviderById} from "../core/redux/slices/rideProviderSlice";
import ScrollableContent from "../components/ScrollableContent";
import RideProviderForm from "../forms/RideProviderForm";

const RideProviderOrganizationPage = () => {

  const {[routeParameter.rideProviderId]: rideProviderId} = useParams()
  const rideProvider = useSelector((state) => selectRideProviderById(state, rideProviderId))

  return <>
    <PageHeader title={rideProvider?.companyName}
                actions={
                  <ButtonGroup>
                    <WhitePortal name={portalNames.headerBarAction}/>
                  </ButtonGroup>
                }
    />
    <ScrollableContent>
      <RideProviderForm/>
    </ScrollableContent>
  </>
}

export default RideProviderOrganizationPage;