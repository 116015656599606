import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import i18n from "../constants/i18n";
import {useSelector} from "react-redux";
import {localUserSelector} from "../redux/slices/localUserSlice";
import useDispatchAsync from "./useDispatchAsync";
import {edit, getUser, selectUsers} from "../redux/slices/userSlice";

const PUBLIC_APP_SERVER_KEY = 'BKnQL8umm9UA26gInxtxBfTOzVN9n2SMsDh6vcwIa8Mi3nPCkijlTzTmem-tTlpPNL4GLgtDgczr3tgdAn3_Wi8';

const useWebNotifications = () => {

  const [loadingPermission, setLoadingPermission] = useState(false);

  const localUser = useSelector(localUserSelector);
  const {rideProviderId} = localUser;

  const localUserId = localUser?.userId;
  const allUsers = useSelector(selectUsers);
  const userInfo = allUsers.find(user => user.userId === localUserId);

  const [dispatchGetUser] = useDispatchAsync(getUser);
  const [dispatchEditUser] = useDispatchAsync(edit);

  useEffect(() => {
    if (localUserId) {
      dispatchGetUser({userId: localUserId, rideProviderId});
    }
  }, [localUserId])

  const pushNotificationsAvailable = () => {
    return ('serviceWorker' in navigator) && ('PushManager' in window);
  }
  const handleAllowNotificationsAsync = async () => {
    setLoadingPermission(true);

    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        const registration = await navigator.serviceWorker.register('/service-worker.js?v=2');
        await navigator.serviceWorker.ready;
        const pushSubscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(PUBLIC_APP_SERVER_KEY),
        });

        const pushSubscriptionObj = JSON.parse(JSON.stringify(pushSubscription));
        const updatedUser = {
          ...userInfo.values,
          pushSubscription: pushSubscriptionObj,
        }

        await dispatchEditUser(updatedUser);
        toast.success(i18n.t("successfully enabled notifications"));
      }

    } catch (e) {
      toast.error(i18n.t("could not enable notifications"));
      console.error("Web Notification Error: ", e);
    } finally {
      setLoadingPermission(false);
    }
  }

  const handleUnsubscribeAsync = async () => {
    setLoadingPermission(true);

    try {
      const updatedUser = {
        ...userInfo.values,
        clearPushSubscription: true,
      }

      await dispatchEditUser(updatedUser);
      toast.success(i18n.t("successfully disabled notifications"));
    } finally {
      setLoadingPermission(false);
    }
  }

  return {
    pushNotificationsAvailable,
    subscribed: userInfo?.hasPushSubscription,
    handleAllowNotificationsAsync,
    handleUnsubscribeAsync,
    loadingPermission,
  }
}

function urlBase64ToUint8Array(base64String) {
  let padding = '='.repeat((4 - base64String.length % 4) % 4);
  let base64 = (base64String + padding)
  .replace(/\-/g, '+')
  .replace(/_/g, '/');

  let rawData = window.atob(base64);
  let outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export default useWebNotifications;