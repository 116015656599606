import {Enum} from "./Enum";
import i18n from "../constants/i18n";

export class RideOrdererState extends Enum {
    static ACTIVE = Symbol("ACTIVE");
    static DISSOLVED = Symbol("DISSOLVED");

    get uiString(){
        return i18n.t(`RideOrdererState ${this.value.description}`)
    }

    constructor (state){
        super(state)
    }
}