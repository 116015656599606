import {Checkbox, ControlDecorator, DynamicGrid, DynamicRow, Input} from "@odm/ui";
import i18n from "../core/constants/i18n";
import {Field, getIn, useFormikContext} from "formik";

const fieldNames = {
    firstName: "firstName",
    lastName: "lastName",
    phone: "phone",
    email: "email",
    emailNotificationsEnabled: "emailNotificationsEnabled"
}

const UserFormSection = () => {
    const { isSubmitting,errors,touched, handleChange, values } = useFormikContext();

    return (
        <fieldset disabled={isSubmitting}>
            <DynamicGrid numCols={2}>
                <DynamicRow>
                    <ControlDecorator label={i18n.t("email")}
                                      error={getIn(touched,fieldNames.email) && getIn(errors,fieldNames.email)}
                    >
                        <Field as={Input} id="input_email" name={fieldNames.email} />
                    </ControlDecorator>
                    <ControlDecorator label={true}>
                        <Checkbox label={i18n.t("email notification enabled")}
                                  name={fieldNames.emailNotificationsEnabled}
                                  checked={getIn(values,fieldNames.emailNotificationsEnabled)}
                                  onChange={handleChange}
                        />
                    </ControlDecorator>
                </DynamicRow>
                <DynamicRow>
                    <ControlDecorator label={i18n.t("firstname")}
                                      error={getIn(touched,fieldNames.firstName) && getIn(errors,fieldNames.firstName)}
                    >
                        <Field as={Input} id="input_firstname" name={fieldNames.firstName} />
                    </ControlDecorator>
                    <ControlDecorator label={i18n.t("lastname")}
                                      error={getIn(touched,fieldNames.lastName) && getIn(errors,fieldNames.lastName)}
                    >
                        <Field as={Input} id="input_lastname" name={fieldNames.lastName} />
                    </ControlDecorator>
                </DynamicRow>
                <DynamicRow>
                    <ControlDecorator label={i18n.t("phone")}
                                      error={getIn(touched,fieldNames.phone) && getIn(errors,fieldNames.phone)}
                    >
                        <Field as={Input} id="input_phone" name={fieldNames.phone} />
                    </ControlDecorator>
                </DynamicRow>
            </DynamicGrid>
        </fieldset>
    )
}

export default UserFormSection