import {ButtonGroup, ControlDecorator, DynamicRow, IconButton, Input, SegmentedButton} from "@odm/ui";
import i18n from "../core/constants/i18n";
import {Field, getIn, useFormikContext} from "formik";
import {WeekDay} from "../core/models/WeekDay";
import {MdOutlineCancel} from "react-icons/md";


const BusinessHoursFormSection = ({prefix, index, onRemove}) => {

    const {errors,values,setFieldValue} = useFormikContext();

    const fieldNames = {
        from: [prefix,"from"].join("."),
        till: [prefix,"till"].join("."),
        days: [prefix,"days"].join(".")
    }

    const dayOfWeekOptions = [
        {
            label: i18n.t("monday abbr"),
            value: new WeekDay(WeekDay.MONDAY.description)
        },
        {
            label: i18n.t("tuesday abbr"),
            value: new WeekDay(WeekDay.TUESDAY.description)
        },
        {
            label: i18n.t("wednesday abbr"),
            value: new WeekDay(WeekDay.WEDNESDAY.description)
        },
        {
            label: i18n.t("thursday abbr"),
            value: new WeekDay(WeekDay.THURSDAY.description)
        },
        {
            label: i18n.t("friday abbr"),
            value: new WeekDay(WeekDay.FRIDAY.description)
        },
        {
            label: i18n.t("saturday abbr"),
            value: new WeekDay(WeekDay.SATURDAY.description)
        },
        {
            label: i18n.t("sunday abbr"),
            value: new WeekDay(WeekDay.SUNDAY.description)
        }
    ]

    return (
      <DynamicRow>
          <ControlDecorator label={!index ? i18n.t("days of week") : null}>
              <SegmentedButton items={dayOfWeekOptions}
                               id="segmented-button_days-of-week"
                               onChange={(days)=>setFieldValue(fieldNames.days,days)}
                               value={getIn(values,fieldNames.days)}
                               multiple={true}
                               compareFn={(item, value)=> item.value === value.value}
              />
          </ControlDecorator>
              <ControlDecorator label={!index ? i18n.t("start of business hours") : null}
                                error={getIn(errors,fieldNames.from)}
              >
                  <Field as={Input} id="input_start-of-business-hours" type={"time"} name={fieldNames.from}/>
              </ControlDecorator>

              <ControlDecorator label={!index ? i18n.t("end of business hours") : null}
                                error={getIn(errors,fieldNames.till)}
              >
                  <Field as={Input} id="input_end-of-business-hours" type={"time"} name={fieldNames.till}/>
              </ControlDecorator>
              <ButtonGroup>
                  <IconButton
                    onClick={onRemove}
                    title={i18n.t("remove time period")}
                    id={"icon-button_remove-time-period-" + index}
                    icon={<MdOutlineCancel/>}/>
              </ButtonGroup>
      </DynamicRow>
    )
}

export default BusinessHoursFormSection;
