import React from "react";
import {CONTROL_APPEARANCE, ControlDecorator, Input} from "@odm/ui";
import {Field, getIn, useFormikContext} from "formik";
import i18n from "../core/constants/i18n";
import {LiaFileInvoiceDollarSolid} from "react-icons/lia";
import {BiIdCard} from "react-icons/bi";

const PayerFormSection = () => {
  const {touched, errors, isSubmitting} = useFormikContext();

  return <ControlDecorator icon={<LiaFileInvoiceDollarSolid/>}
                           error={getIn(touched, "payerInformation") && getIn(errors, "payerInformation")}
                           label={i18n.t("payerInformation")}
                           labelFor={"payerInformation"}>
    <Field as={Input}
           id="input_payer-information"
           name={"payerInformation"}
           disabled={isSubmitting}
           controlAppearance={CONTROL_APPEARANCE.filled}
           placeholder={i18n.t("payer placeholder")}
    />
  </ControlDecorator>

}

export default PayerFormSection;