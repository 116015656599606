import {Outlet} from "react-router-dom";
import {SidebarContainer} from "@odm/ui";
import RideProviderSidebar from "../components/RideProviderSidebar";


const RideProviderPage = () => {


       return(
        <>
            <SidebarContainer sidebar={
                <RideProviderSidebar />
            }>
              <Outlet />
            </SidebarContainer>
        </>
    )
}

export default RideProviderPage;