import {Button, BUTTON_EMPHASIS, BUTTON_VARIANTS, InlineError, Modal} from "@odm/ui";
import i18n from "../core/constants/i18n";
import {Formik} from "formik";
import UserFormSection from "../forms/UserFormSection";
import {edit} from "../core/redux/slices/userSlice";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import {VALIDATION_SCHEMATA} from "../core/constants/validationSchemata";
import {toast} from "react-toastify";


const UserEditModal = ({user, isOpen, onClose}) => {

    const [dispatchEdit,error] = useDispatchAsync(edit);

    const handleEditUser = (values, formikHelper) => {
        return dispatchEdit((values)).then(()=>{
            toast.success(i18n.t("edit successful"));
            handleClose(formikHelper.resetForm)
        })
    }

    const handleClose = (resetForm) => {
        resetForm({
            values: user
        })
        onClose()
    }

    return (
            <Formik initialValues={user?.formValues()}
                onSubmit={handleEditUser}
                enableReinitialize={true}
                    validateOnChange={true}
                    validationSchema={VALIDATION_SCHEMATA.user}
            >
            {({handleSubmit, isSubmitting, resetForm, dirty,isValid})=>(
                <Modal isOpen={isOpen}
                       onClose={() => handleClose(resetForm)}
                       title={i18n.t("edit user")}
                       actionsSlot={<>
                           <Button title={i18n.t("cancel")}
                                   id="button_cancel"
                                   variant={BUTTON_VARIANTS.cancel}
                                   emphasis={BUTTON_EMPHASIS.low}
                                   onClick={()=> handleClose(resetForm)}
                           />
                           <Button title={i18n.t("save changes")}
                                   id="button_save"
                                   onClick={handleSubmit}
                                   variant={BUTTON_VARIANTS.confirm}
                                   emphasis={BUTTON_EMPHASIS.high}
                                   loading={isSubmitting}
                                   disabled={!isValid || !dirty}
                           />

                       </>}
                >
                    <UserFormSection />
                    <InlineError errorMessage={
                        error?.message.includes('409') && i18n.t("user duplicated email") ||
                        error?.message.includes('400') && i18n.t("user edit rejected") ||
                        (error && i18n.t("could not edit user"))
                    }/>
                </Modal>
            )}
        </Formik>
    )
}
export default UserEditModal;