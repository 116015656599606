import React from "react";
import {RideOrderState} from "../core/models/RideOrderState";
import {BsCircle, BsFillPersonCheckFill, BsFillPersonFill} from "react-icons/bs";
import {TbNorthStar} from "react-icons/tb";
import {FiEdit2} from "react-icons/fi";
import {AiFillCheckCircle} from "react-icons/ai";
import "./OrderStateBadge.scss";
import {HiChevronDoubleRight} from "react-icons/hi";
import classNames from "classnames";
import {GoDesktopDownload} from "react-icons/go";
import {IoWarning} from "react-icons/io5";

const OrderStateBadge = ({state}) => {
  if (!state) return null;
  const iconColor = "white";

  let color, icon;
  switch (state.value) {
    case RideOrderState.NEW.valueOf(): {
      color = "#4b7bec";
      icon = <TbNorthStar color={iconColor}/>;
      break;
    }
    case RideOrderState.CHANGED.valueOf(): {
      color = "#4b7bec";
      icon = <FiEdit2 color={iconColor}/>;
      break;
    }
    case RideOrderState.EN_ROUTE.valueOf(): {
      color = "#fa8231";
      icon = <HiChevronDoubleRight color={iconColor}/>;
      break;
    }
    case RideOrderState.ARRIVED_AT_PICKUP_LOCATION.valueOf(): {
      color = "#8854d0";
      icon = <BsFillPersonFill color={iconColor}/>;
      break;
    }
    case RideOrderState.PICKUP_COMPLETE.valueOf(): {
      color = "#8854d0";
      icon = <BsFillPersonCheckFill color={iconColor}/>;
      break;
    }
    case RideOrderState.ACCEPTED.valueOf(): {
      color = "#20bf6b";
      icon = <AiFillCheckCircle color={iconColor}/>;
      break;
    }
    case RideOrderState.COMPLETED.valueOf(): {
      color = "#20bf6b";
      icon = <AiFillCheckCircle color={iconColor}/>;
      break;
    }
    case RideOrderState.COMMITTED.valueOf(): {
      color = "#4b7bec";
      icon = <GoDesktopDownload color={iconColor}/>;
      break;
    }
    case RideOrderState.REJECTED.valueOf(): {
      color = "#f7b731";
      icon = <IoWarning color={iconColor}/>;
      break;
    }
    default: {
      color = "#778ca3";
      icon = <BsCircle color={iconColor}/>;
    }
  }

  return <div className={"oder-state-badge"}>
    <div className={classNames("icon-color-container", {
      "sway": state.value === RideOrderState.EN_ROUTE.valueOf(),
      "flash": state.value === RideOrderState.REJECTED.valueOf(),
    })}
         style={{backgroundColor: color}}>
      {icon}
    </div>
    <div className={"label"}>
      {state.uiString}
    </div>
  </div>
}

export default OrderStateBadge;