import { Formik } from "formik";
import { Button, BUTTON_EMPHASIS, BUTTON_VARIANTS, CardActions, InlineError, Modal } from "@odm/ui";
import i18n from "../core/constants/i18n";
import RideOrderDialogFormSection from "../forms/RideOrderDialogFormSection";
import { VALIDATION_SCHEMATA } from "../core/constants/validationSchemata";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { localUserSelector } from "../core/redux/slices/localUserSlice";
import { selectRideOrdererById } from "../core/redux/slices/rideOrdererSlice";
import { selectRideProviderById } from "../core/redux/slices/rideProviderSlice";
import { assignReturn } from "../core/redux/slices/rideOrdersSlice";
import { DateTime } from "../core/models/DateTime";
import RideOrder from "../core/models/RideOrder";

const RideOrderReturnRideDialog = ({ rideOrder, isOpen, onClose }) => {
  const [ dispatchAssignReturn, error ] = useDispatchAsync(assignReturn, [isOpen]);
  const { rideOrdererId, rideProviderId } = useSelector(localUserSelector);
  const rideOrderer = useSelector((state) => selectRideOrdererById(state, rideOrdererId));
  const rideProvider = useSelector((state) => selectRideProviderById(state, rideProviderId));
  const additionalFormBlocks = rideOrderer?.additionalFormBlocks || rideProvider?.pageInformation?.additionalFormBlocks || {};
  const initialValues = rideOrder?.formValues();

  let referenceRideDateTime = null;
  let referenceTimeOfRide = null;

  if (initialValues) {
    const { startLocation, endLocation, timeOfRide, endLocationArrivalTime, dateOfRide, rideOrderId } = initialValues;

    referenceTimeOfRide = endLocationArrivalTime ? endLocationArrivalTime : timeOfRide;
    referenceRideDateTime = new Date(dateOfRide + " " + referenceTimeOfRide);
  

    const newTime = new DateTime(
        new Date(referenceTimeOfRide.toDate().getTime() + 2 * 60 * 60 * 1000),
        { formatString: "HH:mm:ss" }
    );

    if (newTime.value.getDate() !== referenceTimeOfRide.toDate().getDate()) {
        initialValues.dateOfRide = new DateTime(
            new Date(dateOfRide.toDate().getTime() + 24 * 60 * 60 * 1000),
            { formatString: "yyyy-MM-dd" }
        );
    }

    initialValues.isArrivalTime = RideOrder.calculateTimingValues(rideProvider, rideOrderer, initialValues.isArrivalTime);
    initialValues.outwardRideOrderId = rideOrderId;
    initialValues.timeOfRide = newTime;
    initialValues.startLocation = endLocation;
    initialValues.endLocation = startLocation;
    initialValues.outward = startLocation;
  }

  const handleAddOrder = async (values) => {
    try {
        await dispatchAssignReturn(values);
        toast.success(i18n.t("create order successful"));
        onClose();
    } catch (error) {
        console.error(error);
        toast.error(i18n.t("create order failed"));
    }
  };

  return (
    <Modal title={i18n.t("order return ride")} isOpen={isOpen} onClose={onClose}>
      <div className={"ride-order-edit-dialog"}>
        <Formik initialValues={initialValues}
                validateOnMount={true}
                validateOnChange={true}
                validationSchema={VALIDATION_SCHEMATA.dynamicRideOrder({
                  additionalFormBlocks,
                  rideProvider,
                  rideOrder,
                  referenceRideDateTime
                })}
                onSubmit={handleAddOrder}
        >
          {({
            isSubmitting,
            handleSubmit,
            isValid,
          }) => (
              <form onSubmit={handleSubmit}>
                <RideOrderDialogFormSection rideOrder={rideOrder} showReasonForLastChange={false}/>
                <InlineError errorMessage={!!error && i18n.t("Order rejected")}/>
                <CardActions>
                  <Button title={i18n.t("cancel")}
                          id="button_cancel"
                          emphasis={BUTTON_EMPHASIS.low}
                          variant={BUTTON_VARIANTS.cancel}
                          onClick={onClose}
                          disabled={isSubmitting}
                  />
                  <Button title={i18n.t("orderNow")}
                          id="button_order"
                          type={"submit"}
                          disabled={!isValid}
                          loading={isSubmitting}
                  />
                </CardActions>
              </form>
          )}
        </Formik>
      </div>
    </Modal>
    )
};

export default RideOrderReturnRideDialog;
