import i18n from "../core/constants/i18n";
import {Checkbox, Textarea} from "@odm/ui";
import {useFormikContext} from "formik";

const RideOrderInfectionFormSection = ()=>{

    const {values, handleChange, isSubmitting} = useFormikContext();

    return (
        <>
            <Checkbox label={i18n.t("infection")}
                      id="checkbox_infected"
                      onChange={handleChange}
                      name={"infected"}
                      checked={values.infected}
                      disabled={isSubmitting}
            />
            <Textarea placeholder={i18n.t("comment for infection")}
                      id="input_comment-for-infection"
                      disabled={!values.infected || isSubmitting}
                      onChange={handleChange}
                      value={values.infectedComment}
                      name={"infectedComment"}
                      controlAppearance={"outlined"}
            />
        </>
    )
}

export default RideOrderInfectionFormSection;