import React, {useEffect, useState} from "react";
import {
  Button,
  BUTTON_EMPHASIS,
  ButtonGroup,
  SECTION_MESSAGE_APPEARANCES,
  SECTION_MESSAGE_VARIANTS,
  SectionMessage,
  TransitionContainer,
  TRANSITION_CONTAINER_ANIMATIONS
} from "@odm/ui";
import {FaBell} from "react-icons/fa";
import i18n from "../core/constants/i18n";
import useWebNotifications from "../core/hooks/useWebNotifications";
import {isNil} from "lodash";
import {useSelector} from "react-redux";
import {localUserSelector} from "../core/redux/slices/localUserSlice";

const LOCAL_STORAGE_DISMISS_KEY = "dismissPushNotifications";

const PushNotificationPrompt = () => {
  const localUser = useSelector(localUserSelector);
  const [notificationPermissionDenied, setNotificationPermissionDenied] = useState(false);

  const {
    pushNotificationsAvailable,
    subscribed,
    handleAllowNotificationsAsync,
    loadingPermission,
  } = useWebNotifications();

  useEffect(() => {
    if (localUser) {
      const dismissMap = JSON.parse(localStorage.getItem(LOCAL_STORAGE_DISMISS_KEY) || "{}");
      setNotificationPermissionDenied(Boolean(dismissMap[localUser.userId]));
    }
  }, [localUser]);

  useEffect(() => {
    if (notificationPermissionDenied && localUser?.userId) {
      const dismissMap = JSON.parse(localStorage.getItem(LOCAL_STORAGE_DISMISS_KEY) || "{}");
      dismissMap[localUser.userId] = notificationPermissionDenied;
      localStorage.setItem(LOCAL_STORAGE_DISMISS_KEY, JSON.stringify(dismissMap));
    }
  }, [notificationPermissionDenied]);

  const shouldShowPrompt = (!notificationPermissionDenied && subscribed === false);
  return <TransitionContainer show={shouldShowPrompt && pushNotificationsAvailable()}
                              transition={TRANSITION_CONTAINER_ANIMATIONS.height}>

    <SectionMessage title={i18n.t("push notification prompt")}
                    message={""}
                    appearance={SECTION_MESSAGE_APPEARANCES.compact}
                    variant={SECTION_MESSAGE_VARIANTS.info}
                    icon={<FaBell/>}
                    actionsSlot={<ButtonGroup>
                      <Button title={i18n.t("allow notifications")}
                              id="button_allow-notifications"
                              loading={loadingPermission}
                              disabled={loadingPermission}
                              onClick={handleAllowNotificationsAsync} emphasis={BUTTON_EMPHASIS.high}/>

                      <Button title={i18n.t("deny notifications")}
                              id="button_deny-notifications"
                              disabled={loadingPermission}
                              onClick={() => {
                                setNotificationPermissionDenied(true);
                              }} emphasis={BUTTON_EMPHASIS.minimal}/>
                    </ButtonGroup>}/>

  </TransitionContainer>
}

export default PushNotificationPrompt;