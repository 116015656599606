import i18n from "../core/constants/i18n";
import {Field, FieldArray, Formik, getIn} from "formik";
import PlaceFormSection from "../forms/PlaceFormSection";
import RideProvider from "../core/models/RideProvider";
import ContactFormSection from "../forms/ContactFormSection";
import BusinessHoursFormSection from "../forms/BusinessHoursFormSection";
import {
  BodyText,
  Button,
  BUTTON_EMPHASIS,
  BUTTON_VARIANTS,
  ContentContainer,
  ControlDecorator, Dropdown, DROPDOWN_POSITIONS, DynamicGrid, DynamicRow,
  EmptyState, Fieldset,
  InlineError,
  Input, MenuEntry,
  PageSection,
  SectionError
} from "@odm/ui";
import {useSelector} from "react-redux";
import {edit, selectRideProviderById} from "../core/redux/slices/rideProviderSlice";
import UserDissolveModal from "../components/UserDissolveModal";
import UserAddModal from "../components/UserAddModal";
import {UserRole} from "../core/models/UserRole";
import UserEditModal from "../components/UserEditModal";
import React, {Fragment, useEffect, useState} from "react";
import UsersTable from "../components/UsersTable";
import {getUsers, selectAdminUsersByRideProvider} from "../core/redux/slices/userSlice";
import {MdDelete} from "react-icons/md";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import {adminRideProviders, routeParameter} from "../core/constants/routes";
import {useNavigate, useParams} from "react-router-dom";
import {VALIDATION_SCHEMATA} from "../core/constants/validationSchemata";
import {toast} from "react-toastify";
import {localUserSelector} from "../core/redux/slices/localUserSlice";
import {FiMoreHorizontal} from "react-icons/fi";
import RideProviderDissolveModal from "../components/RideProviderDissolveModal";
import {BlackPortal} from "react-native-portal";
import portalNames from "../core/constants/portalNames";
import NarrowContainer from "../components/NarrowContainer";
import {FaUserSlash} from "react-icons/fa";
import FormErrorIcon from "../components/FormErrorIcon";
import randomstring from "randomstring";
import {BiRefresh} from "react-icons/bi";
import BusinessHoursInformation from "../core/models/BusinessHoursInformation";
import CustomScheduledTripsFormSection from "./CustomScheduledTripsFormSection";

const fieldNames = {
  placeOfBusiness: "placeOfBusiness",
  companyName: "companyName",
  licenseNumber: "licenseNumber",
  tenant: "tenant",
  department: "department",
  contact: "contact",
  businessHours: "businessHours",
  apiKey: "apiKey",
  cancellationLimitDays: "cancellationLimitDays",
  orderLimitDays: "orderLimitDays"
}

const modals = {
  addUser: "addUser",
  dissolveUser: "dissolveUser",
  editUser: "editUser",
}

const RideProviderForm = () => {
  const {[routeParameter.rideProviderId]: rideProviderId} = useParams()
  const rideProvider = useSelector((state) => selectRideProviderById(state, rideProviderId))
  const localUser = useSelector(localUserSelector);
  const [openModal, setOpenModal] = useState(null);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const [currentUser, setCurrentUser] = useState(null);
  const admins = useSelector(state => selectAdminUsersByRideProvider(state, rideProviderId))
  const [dispatchGetUsers, usersError] = useDispatchAsync(getUsers);
  const [dispatchEdit, editError] = useDispatchAsync(edit);

  const navigate = useNavigate();

  useEffect(() => {
    if (rideProvider) {
      dispatchGetUsers({rideProviderId})
    }
  }, [rideProvider])

  const openDissolveUserModal = (user) => {
    setCurrentUser(user);
    setOpenModal(modals.dissolveUser);
  }
  const openEditUserModal = (user) => {
    setCurrentUser(user);
    setOpenModal(modals.editUser);
  }
  const openAddUserModal = () => {
    setCurrentUser(null);
    setOpenModal(modals.addUser);
  }

  const handleEditRideProvider = (values) => {
    return dispatchEdit(values).then(() => {
      toast.success(i18n.t("edit successful"))
    }).catch(() => {
      toast.error(i18n.t("edit failed"))
    })
  }

  const buildUserTable = () => {
    if (admins.length > 0) {
      return (
          <UsersTable users={admins}
                      scope="admin"
                      onDissolve={openDissolveUserModal}
                      onEdit={openEditUserModal}
                      rideProviderId={rideProviderId}
          />
      )
    } else if (!usersError) {
      return (
          <EmptyState title={i18n.t("no admins title")}
                      description={i18n.t("no admins description")}
                      icon={<FaUserSlash/>}
                      actions={<Button title={i18n.t("add admin")}
                                       id="button_add-admin"
                                       variant={BUTTON_VARIANTS.add}
                                       emphasis={BUTTON_EMPHASIS.high}
                                       onClick={openAddUserModal}
                      />}
          />
      )
    }
    return null;
  }

  return (
      <>
          <Formik
              initialValues={rideProvider?.formValues() ?? RideProvider.formValues()}
              onSubmit={handleEditRideProvider}
              enableReinitialize={true}
              validateOnChange={true}
              validationSchema={VALIDATION_SCHEMATA.rideProvider}
          >
            {({submitForm, setFieldValue, isValid, dirty, errors,values}) => (
                <>
                  <BlackPortal name={portalNames.headerBarAction}>
                    <FormErrorIcon dirty={dirty} isValid={isValid}/>
                    <Button title={i18n.t("save")}
                            id="button_save"
                            variant={BUTTON_VARIANTS.save}
                            disabled={!dirty || !isValid}
                            onClick={submitForm}
                    />
                    <Dropdown position={DROPDOWN_POSITIONS.bottomEnd}
                              openOnHover={false}
                              trigger={(toggle) => <Button aria-label="more"
                                                           id="button_dropdown-more"
                                                           onClick={toggle}
                                                           icon={<FiMoreHorizontal/>}
                                                           emphasis={BUTTON_EMPHASIS.low}
                              />}>
                      <MenuEntry menuItem={{
                        label: i18n.t("dissolve rideProvider"),
                        onClick: () => setDeleteModalIsOpen(true),
                        icon: <MdDelete/>,
                        id: "menu-item_dissolve-ride-provider",
                      }}/>
                    </Dropdown>
                  </BlackPortal>
                  <InlineError errorMessage={editError && i18n.t("rideProvider edit rejected")}/>
                  <ContentContainer>
                    <NarrowContainer>
                      <PageSection id={'general'} title={i18n.t("general")}>
                        <ControlDecorator label={i18n.t("company name")}
                                          error={getIn(errors, fieldNames.companyName)}
                        >
                          <Field as={Input}
                                 id="input_company-name"
                                 name={fieldNames.companyName}
                          />
                        </ControlDecorator>
                        {(localUser?.isAdmin || localUser?.isSuperAdmin) ? (
                            <Fragment>
                              <ControlDecorator label={i18n.t("license number")}
                                                error={getIn(errors, fieldNames.licenseNumber)}
                              >
                                <Field as={Input}
                                       id="input_license-number"
                                       disabled={!localUser?.isSuperAdmin}
                                       name={fieldNames.licenseNumber}

                                />
                              </ControlDecorator>
                            <ControlDecorator label={i18n.t("tenant")}
                                              error={getIn(errors, fieldNames.tenant)}
                            >
                              <Field as={Input}
                                     id="input_tenant"
                                     disabled={!localUser?.isSuperAdmin}
                                     name={fieldNames.tenant}

                              />
                            </ControlDecorator>
                            <ControlDecorator label={i18n.t("department")}
                                              error={getIn(errors, fieldNames.department)}
                            >
                              <Field as={Input}
                                     id="input_department"
                                     disabled={!localUser?.isSuperAdmin}
                                     name={fieldNames.department}

                              />
                            </ControlDecorator>
                              <ControlDecorator label={i18n.t("api key")}>
                                <Field as={Input}
                                       id="input_api-key"
                                       disabled
                                       name={fieldNames.apiKey}
                                />
                              </ControlDecorator>

                              {localUser?.isSuperAdmin ?
                                  <Button title={i18n.t("generate new api key")}
                                          id="button_generate-api-key"
                                          onClick={() => {
                                            setFieldValue(fieldNames.apiKey, randomstring.generate());
                                          }}
                                          icon={<BiRefresh/>}
                                          emphasis={BUTTON_EMPHASIS.low}
                                  /> : null}
                            </Fragment>
                        ) : null}

                      </PageSection>
                      <PageSection id={'address'} title={i18n.t("postal address")}>
                        <PlaceFormSection prefix={fieldNames.placeOfBusiness}/>
                      </PageSection>
                      <PageSection title={i18n.t("contact")}>
                        <ContactFormSection prefix={fieldNames.contact}/>
                        <SectionError errorMessage={(typeof errors[fieldNames.contact] == "string") && errors[fieldNames.contact]}/>
                      </PageSection>
                      <PageSection title={i18n.t("restrictions")}>
                        <DynamicGrid numCols={2}>
                          <BodyText>{i18n.t("restrictions description")}</BodyText>
                          <Fieldset>
                            <DynamicRow>
                              <ControlDecorator label={i18n.t("prevent cancellation days")}
                                                error={getIn(errors, fieldNames.cancellationLimitDays)}
                              >
                                <Field as={Input}
                                       id="input_prevent-cancellation-days"
                                       type={"number"}
                                       min={0}
                                       max={100}
                                       name={fieldNames.cancellationLimitDays}

                                />
                              </ControlDecorator>
                              <ControlDecorator label={i18n.t("prevent order days")}
                                                error={getIn(errors, fieldNames.orderLimitDays)}
                              >
                                <Field as={Input}
                                       id="input_prevent-order-days"
                                       type={"number"}
                                       min={0}
                                       max={100}
                                       name={fieldNames.orderLimitDays}

                                />
                              </ControlDecorator>
                            </DynamicRow>
                          </Fieldset>

                        </DynamicGrid>
                      </PageSection>
                    </NarrowContainer>

                    <PageSection id={'scheduled-trips-form'} title={i18n.t("scheduled trips form")}>
                      <NarrowContainer>
                        <PageSection>
                          <CustomScheduledTripsFormSection />
                        </PageSection>
                      </NarrowContainer>
                    </PageSection>

                    <FieldArray name={"businessHours"}>
                      {({push,remove})=> {

                        const businessHours = getIn(values, fieldNames.businessHours);

                        return (
                          <PageSection id={'availability'}
                                       title={i18n.t("business hours")}
                                       actionsSlot={
                                         <Button title={i18n.t("add time period")}
                                                 id="button_add-time-period"
                                                 variant={BUTTON_VARIANTS.add}
                                                 emphasis={BUTTON_EMPHASIS.low}
                                                 onClick={() => push(BusinessHoursInformation.formValues())}
                                         />
                                       }
                          >
                            {businessHours?.length > 0
                              ? (
                                <DynamicGrid numCols={4}>
                                  {(businessHours).map((time, index) => (
                                    <BusinessHoursFormSection prefix={`${fieldNames.businessHours}.${index}`}
                                                              index={index}
                                                              onRemove={() => remove(index)}
                                    />
                                  ))
                                  }
                                </DynamicGrid>
                              )
                              : <SectionError errorMessage={(typeof errors[fieldNames.businessHours] == "string") && errors[fieldNames.businessHours]}/>
                            }
                        </PageSection>
                      )}
                      }
                    </FieldArray>
                  </ContentContainer>
                </>
            )}
          </Formik>

        <ContentContainer>

          <PageSection title={i18n.t("administrators")}
                       actionsSlot={<Button title={i18n.t("add admin")}
                                        id="button_add-admin"
                                        variant={BUTTON_VARIANTS.add}
                                        emphasis={BUTTON_EMPHASIS.low}
                                        onClick={openAddUserModal}
                       />}
          >
            <SectionError errorMessage={usersError && i18n.t("get users rejected")}/>
            {buildUserTable()}

          </PageSection>
        </ContentContainer>

        <UserDissolveModal isOpen={openModal === modals.dissolveUser}
                           onClose={() => setOpenModal(null)}
                           user={currentUser}
        />
        <UserAddModal isOpen={openModal === modals.addUser}
                      onClose={() => setOpenModal(null)}
                      title={i18n.t("add admin")}
                      partialInitialValues={{
                        rideProviderId,
                        role: UserRole.ADMIN.description
                      }}
        />
        <UserEditModal isOpen={openModal === modals.editUser}
                       onClose={() => setOpenModal(null)}
                       user={currentUser}
        />
        <RideProviderDissolveModal isOpen={deleteModalIsOpen}
                                   rideProvider={rideProvider}
                                   onClose={() => setDeleteModalIsOpen(false)}
                                   onDissolved={() => {
                                     navigate(adminRideProviders)
                                   }}
        />
      </>
  )
}

export default RideProviderForm