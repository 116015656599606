import {createSelector, current} from "@reduxjs/toolkit";
import rideOrderController from "../../http/controller/rideOrderController";
import RideOrder from "../../models/RideOrder";
import {RideOrderState} from "../../models/RideOrderState";
import {EntitySliceHelper} from "../../util/EntitySliceHelper";
import {parse} from "date-fns";
import { RideOrderType } from "../../models/RideOrderType";

const helper = new EntitySliceHelper("rideOrder",RideOrder)

export const {  selectAll: selectRideOrders, selectById: selectRideOrderById }= helper.getSelectors();

export const selectUncanceled = createSelector(selectRideOrders,(rideOrders)=> rideOrders.filter((rideOrder)=>rideOrder.rideOrderState.value !== RideOrderState.CANCELED))

export const selectOpen = createSelector(selectUncanceled,(rideOrders)=> {
    return rideOrders.filter(rideOrder => {
        switch(rideOrder.rideOrderState.value) {
            case(RideOrderState.NEW):
            case(RideOrderState.CHANGED):
            case(RideOrderState.COMMITTED):
            case(RideOrderState.ACCEPTED):
            case(RideOrderState.EN_ROUTE):
            case(RideOrderState.ARRIVED_AT_PICKUP_LOCATION):
            case(RideOrderState.PICKUP_COMPLETE):{
                return true;
            }
            default: return false
        }
    })
})


export const selectFinished = createSelector(selectUncanceled,(rideOrders)=> {
    return rideOrders.filter(rideOrder => {
        switch(rideOrder.rideOrderState.value) {
            case(RideOrderState.COMPLETED):{
                return true;
            }
            default: return false
        }
    })
})

export const selectRejected = createSelector(selectUncanceled,(rideOrders)=> {
    return rideOrders.filter(rideOrder => {
        switch(rideOrder.rideOrderState.value) {
            case(RideOrderState.REJECTED):{
                return true;
            }
            default: return false
        }
    })
})

export const selectOpenSortAscending = createSelector(selectOpen,(rideOrders)=> {
    const format = RideOrder.schema.timeOfRide[1].formatString

    return rideOrders
        .sort((a, b) => {
            const timeOfA = parse(a.timeOfRide, format, a.dateOfRide.value)
            const timeOfB = parse(b.timeOfRide, format, b.dateOfRide.value)

            return timeOfA - timeOfB
        })
})

export const selectOutwardSortAscending = createSelector(selectOpenSortAscending, (rideOrders) => {
    return rideOrders.filter(rideOrder => !rideOrder.rideOrderType || rideOrder.rideOrderType === RideOrderType.OUTWARD);
});


export const selectReturnSortAscending = createSelector(selectOpenSortAscending,(rideOrders)=> {
    return rideOrders.filter(rideOrder => rideOrder.rideOrderType === RideOrderType.RETURN)
})

export const selectFinishedSortDescending = createSelector(selectFinished,(rideOrders)=> {
    const format = RideOrder.schema.timeOfRide[1].formatString

    return rideOrders
        .sort((a, b) => {
            const timeOfA = parse(a.timeOfRide, format, a.dateOfRide.value)
            const timeOfB = parse(b.timeOfRide, format, b.dateOfRide.value)

            return timeOfB - timeOfA
        })
})





export const getRideOrders = helper.createAsyncAction(rideOrderController.getRideOrdersByRideOrderer);
export const order         = helper.createAsyncAction(rideOrderController.order);
export const assignReturn  = helper.createAsyncAction(rideOrderController.assignReturn);
export const cancelOrder   = helper.createAsyncAction(rideOrderController.cancel);
export const editOrder     = helper.createAsyncAction(rideOrderController.edit);
export const getRideOrder  = helper.createAsyncAction(rideOrderController.getRideOrder);
export const cancelRejected = helper.createAsyncAction(rideOrderController.cancelRejected);

const rideOrdersSlice = helper.createSlice({
    extraReducers: builder => {
        builder
            .addCase(getRideOrders.fulfilled,(state,action) =>{
                helper.adapter.setAll(state,action.payload)
            })
            .addCase(order.fulfilled,(state,action) =>{
                helper.adapter.addOne(state,action.payload)
            })
            .addCase(assignReturn.fulfilled, (state, action) => {
                helper.adapter.upsertMany(state, action.payload);
            })
            .addCase(cancelOrder.fulfilled,(state,action) =>{
                helper.adapter.upsertOne(state, action.payload)
            })
            .addCase(editOrder.fulfilled,(state,action) => {
                helper.adapter.upsertOne(state, action.payload)
            })
            .addCase(getRideOrder.fulfilled,(state,action) => {
                helper.adapter.upsertOne(state, action.payload)
            })
            .addCase(cancelRejected.fulfilled,(state,action) => {
                const referencingTransports = Object.entries(current(state).entities)
                  .filter(([,entity]) => action.payload.includes(entity.referencedRideOrderId))
                  .map(([id]) => ({id, changes: {referencedRideOrderId: null, rideOrderType: null}}));

                helper.adapter.updateMany(state,referencingTransports)
                helper.adapter.removeMany(state,action.payload)
            })
    }
})

export default rideOrdersSlice;