import {ControlDecorator, DynamicGrid, DynamicRow, DynamicRowElement, Input} from "@odm/ui";
import i18n from "../core/constants/i18n";
import {Field, getIn, useFormikContext} from "formik";

const ContactFormSection = ({prefix}) => {

    const fieldNames = {
        firstName:  [prefix,"firstName"].join("."),
        lastName:   [prefix,"lastName"].join("."),
        phone:      [prefix,"phone"].join("."),
        email:      [prefix,"email"].join("."),

    }

    const {errors, touched} = useFormikContext();

    return (
        <>
            <DynamicGrid numCols={2}>
                <DynamicRow>
                    <DynamicRowElement colSpan={1}>
                        <ControlDecorator label={i18n.t("firstname")}
                                          error={getIn(touched,fieldNames.firstName) && getIn(errors,fieldNames.firstName)}
                        >
                            <Field as={Input} id="input_firstname" name={fieldNames.firstName} />
                        </ControlDecorator>
                    </DynamicRowElement>
                    <DynamicRowElement colSpan={1}>
                        <ControlDecorator label={i18n.t("lastname")}
                                          error={getIn(touched,fieldNames.lastName) && getIn(errors,fieldNames.lastName)}
                        >
                            <Field as={Input} id="input_lastname" name={fieldNames.lastName} />
                        </ControlDecorator>
                    </DynamicRowElement>
                </DynamicRow>
                <DynamicRow>
                    <DynamicRowElement colSpan={1}>
                        <ControlDecorator label={i18n.t("phone")}
                                          error={getIn(touched,fieldNames.phone) && getIn(errors,fieldNames.phone)}
                        >
                            <Field as={Input} id="input_phone" name={fieldNames.phone} />
                        </ControlDecorator>
                    </DynamicRowElement>
                    <DynamicRowElement colSpan={1}>
                        <ControlDecorator label={i18n.t("email")}
                                          error={getIn(touched,fieldNames.email) && getIn(errors,fieldNames.email)}
                        >
                            <Field as={Input} id="input_email" name={fieldNames.email} />
                        </ControlDecorator>
                    </DynamicRowElement>
                </DynamicRow>
            </DynamicGrid>
        </>
    )
}

export default ContactFormSection;