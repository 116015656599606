import React, {useEffect, useState} from 'react';
import Keycloak from "keycloak-js";
import moment from "moment";
import {getConfig} from "../core/constants/config";
import store from "../core/redux/store";
import localUserSlice from "../core/redux/slices/localUserSlice";

export const keycloak = new Keycloak(getConfig().auth);

const KeycloakAuthProvider = ({children}) => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    keycloak.init({onLoad: 'login-required', 'checkLoginIframe': false}).then(authenticated => {
      setAuthenticated(authenticated);
      if (authenticated) {
        keycloak.loadUserInfo().then(info => {
          if (keycloak.tokenParsed) {
            const {role} = keycloak.tokenParsed;
            store.dispatch(localUserSlice.actions.set({
              ...info,
              role,
            }));
          }
        });
      }
    });
  }, [])

  if (authenticated) {
    return children;
  }

  return null;
}

export const getActiveAccessToken = () => {
  if (keycloak.tokenParsed) {
    return new Promise((resolve, reject) => {

      const expiresAt = moment(keycloak.tokenParsed.exp * 1000);
      if (expiresAt.isBefore(moment().add(getConfig().tokenActivityBufferMs, "ms"))) {
        keycloak.updateToken().then(() => {
          resolve(keycloak.token);
        }).catch(reject);

      } else {
        resolve(keycloak.token);
      }
    });
  }

  return Promise.reject();
};

export default KeycloakAuthProvider;