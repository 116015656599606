import {Button, BUTTON_EMPHASIS, BUTTON_VARIANTS, InlineError, Modal} from "@odm/ui";
import i18n from "../core/constants/i18n";
import {add} from "../core/redux/slices/userSlice";
import User from "../core/models/User";
import UserFormSection from "../forms/UserFormSection";
import {Formik} from "formik";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import {VALIDATION_SCHEMATA} from "../core/constants/validationSchemata";
import {toast} from "react-toastify";

const UserAddModal = ({isOpen, onClose, partialInitialValues, title}) => {

    const [dispatchAdd,error] = useDispatchAsync(add,[isOpen]);

    const handleAddUser = (values, formikHelper) => {
        return dispatchAdd((values)).then(()=>{
          toast.success(i18n.t("create user successful"));
          handleClose(formikHelper.resetForm)
        })
    }
    const handleClose = (resetForm) => {
       resetForm({
          values: User.fromDefaultValues(partialInitialValues).formValues()
       })
       onClose()
    }

    return (<Formik initialValues={User.fromDefaultValues(partialInitialValues).formValues()}
                enableReinitialize={true}
                    validateOnChange={true}
                    onSubmit={handleAddUser}
                validationSchema={VALIDATION_SCHEMATA.user}
            >
            {({handleSubmit, isSubmitting, resetForm, isValid,dirty})=>(
                <Modal isOpen={isOpen}
                       onClose={() => handleClose(resetForm)}
                       title={title || i18n.t("add user")}
                       actionsSlot={<>
                           <Button title={i18n.t("cancel")}
                                   id="button_cancel"
                                   variant={BUTTON_VARIANTS.cancel}
                                   emphasis={BUTTON_EMPHASIS.low}
                                   onClick={() => handleClose(resetForm)}
                           />
                           <Button title={i18n.t("add user")}
                                   id="button_add-user"
                                   onClick={handleSubmit}
                                   variant={BUTTON_VARIANTS.add}
                                   emphasis={BUTTON_EMPHASIS.high}
                                   loading={isSubmitting}
                                   disabled={!isValid || !dirty}
                           />

                       </>}
                >
                    <UserFormSection />
                    <InlineError errorMessage={
                        error?.message.includes('409') && i18n.t("user duplicated email") ||
                        error?.message.includes('400') && i18n.t("user add rejected") ||
                        (error && i18n.t("could not add user"))
                    }/>
                </Modal>
            )}
        </Formik>
    )
}
export default UserAddModal;