import React, { useState, useEffect } from "react";
import i18n from "../core/constants/i18n";
import { BodyText } from "@odm/ui";
import { useSelector } from "react-redux";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import { IoWarning } from "react-icons/io5";
import "./RenderWarning.scss";
import { RideOrderState } from "../core/models/RideOrderState";
import { RideOrderType } from "../core/models/RideOrderType";
import { getRideOrder, selectRideOrderById } from "../core/redux/slices/rideOrdersSlice";
import { toast } from "react-toastify";

const Warning = ({ order }) => {
  const [errorMessages, setErrorMessages] = useState([]);
  const [referencedRideOrder, setReferencedRideOrder] = useState(null);
  const [dispatchGetRideOrder] = useDispatchAsync(getRideOrder);
  const localReferencedRideOrder = useSelector((state) => selectRideOrderById(state, order.referencedRideOrderId));

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (order && order.referencedRideOrderId) {
          const fetchedRideOrder = await dispatchGetRideOrder({ rideOrderId: order.referencedRideOrderId });
          setReferencedRideOrder(fetchedRideOrder.payload);
        }
      } catch (error) {
        console.error(error);
        toast.error(i18n.t("failed to fetch referenced ride order"));
      }
    };

    if (!localReferencedRideOrder) {
      fetchData();
    } else {
      setReferencedRideOrder(localReferencedRideOrder);
    }

    if (referencedRideOrder) {
      const referenceDateTime = new Date(referencedRideOrder.dateOfRide + " " + referencedRideOrder.timeOfRide);
      const rideDateTime = new Date(order.dateOfRide + " " + order.timeOfRide);
      const isReturnRide = order.rideOrderType === RideOrderType.RETURN;
      const rideType = isReturnRide ? i18n.t("outward ride") : i18n.t("return ride");
      const messages = [];

      switch (referencedRideOrder.rideOrderState.value) {
        case RideOrderState.CANCELED:
          messages.push(i18n.t("ride type canceled", { rideType: rideType }));
          break;
        case RideOrderState.REJECTED:
          messages.push(i18n.t("ride type rejected", { rideType: rideType }));
          break;
        case RideOrderState.CHANGED:
          messages.push(i18n.t("ride type changed", { rideType: rideType }));
          break;
        default:
          break;
      }

      if ((!isReturnRide && referenceDateTime <= rideDateTime) || (isReturnRide && referenceDateTime >= rideDateTime)) {
        messages.push(i18n.t(isReturnRide ? "time before outward ride" : "return ride before outward ride"));
      }

      setErrorMessages(messages);
    }
  }, [order, order.referencedRideOrderId, referencedRideOrder]);

  return errorMessages.map((message, index) => (
    <div key={index} className="warning-container">
      <span><IoWarning /></span>
      <BodyText element="p">{message}</BodyText>
    </div>
  ));
};

export default Warning;