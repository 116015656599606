import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";

const useDispatchAsync = (action,deps=[]) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    useEffect(()=>{
        setIsLoading(false);
        setError(null);
    },deps)

    const dispatchAsync = async (payload) => {

        setIsLoading(true);
        const res = await dispatch(action(payload));
        setIsLoading(false)
        setError(res.error ?? null);
        if('error' in res) throw new Error(res.error)

        return res
    }

    return [dispatchAsync,error,isLoading]
}

export default useDispatchAsync;