import { BodyText, Button, BUTTON_EMPHASIS, BUTTON_VARIANTS, InlineError, Modal, SectionMessage } from "@odm/ui";
import i18n from "../core/constants/i18n";
import { cancelOrder, getRideOrder } from "../core/redux/slices/rideOrdersSlice";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import { toast } from "react-toastify";
import { RideOrderType } from "../core/models/RideOrderType";
import { DateTime } from "../core/models/DateTime";

const RideOrderCancelDialog = ({ rideOrder, referencedRideOrder, isLoading, isOpen, onClose }) => {
    const referencedRideOrderDate = new DateTime(
        new Date(referencedRideOrder?.dateOfRide),
        { formatString: "HH:mm:ss" }
    );

    const [dispatchCancelOrder, error] = useDispatchAsync(cancelOrder, [isOpen]);
    const [dispatchGetRideOrder] = useDispatchAsync(getRideOrder);
    
    const referenceMessage = rideOrder?.rideOrderType === RideOrderType.OUTWARD ? "confirmCancelReturnTransport" : "confirmCancelOutwardTransport";

    const handleCancel = async () => {
        try {
            await dispatchCancelOrder(rideOrder.payload());
            if (referencedRideOrder) {
                await dispatchGetRideOrder({ rideOrderId: rideOrder.referencedRideOrderId });
            }
            onClose();
            toast.success(i18n.t("cancel order successful"));
        } catch (error) {
            console.error(error);
            toast.error(i18n.t("cancel order failed"));
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={i18n.t("transportCancel")}
            actionsSlot={
                <>
                    <Button
                        title={i18n.t("cancel")}
                        id="button_cancel"
                        variant={BUTTON_VARIANTS.cancel}
                        emphasis={BUTTON_EMPHASIS.low}
                        onClick={onClose}
                    />
                    <Button
                        disabled={!rideOrder}
                        title={i18n.t("reverse")}
                        id="button_transport-cancel"
                        variant={BUTTON_VARIANTS.delete}
                        onClick={handleCancel}
                    />
                </>
            }
        >
            {!isLoading && (
                <>
                    <BodyText>
                        {i18n.t("confirmCancelTransport", {
                            transport: `${rideOrder?.fullName} ${i18n.t("dateAt")} ${rideOrder?.dateOfRide.localizedDate}`
                        })}
                    </BodyText>
                    {referencedRideOrder && (
                        <SectionMessage
                            appearance="normal"
                            variant="danger"
                            title={i18n.t("alert")}
                            message={i18n.t(referenceMessage, {
                                date: `${i18n.t("dateAt")} ${referencedRideOrderDate.localizedDate}`
                            })}
                        />
                    )}
                    <InlineError errorMessage={error && i18n.t("cancelOrder rejected")} />
                </>
            )}
        </Modal>
    );
};

export default RideOrderCancelDialog;
