import * as Yup from "yup";
import i18n from "./i18n";
import { FORM_BLOCKS } from "../models/PageInformation";
import { RideOrderType } from "../models/RideOrderType";
import { isBefore, parse } from "date-fns";
import { isDate } from "lodash";
import moment from "moment";


const parseDateString = (value, originalValue) => {
    if (!value) return value

    return isDate(originalValue)
        ? originalValue
        : parse(originalValue, "yyyy-MM-dd", new Date());
}

const isFutureRide = (value, context) => {
    const { dateOfRide, timeOfRide } = context.parent; 
    if (!dateOfRide || !timeOfRide) return false;
    const rideDateTime = new Date(dateOfRide + " " + timeOfRide);
    return rideDateTime > new Date();
};

const isAfterReferenceRide = (value, context) => {
    const { dateOfRide, timeOfRide } = context.parent;
    const { referenceRideDateTime } = context.options;

    const rideDateTime = new Date(dateOfRide + " " + timeOfRide);

    return rideDateTime > referenceRideDateTime;
};

const isBeforeReferenceRide = (value, context) => {
    const { dateOfRide, timeOfRide } = context.parent;
    const { referenceRideDateTime } = context.options;

    const rideDateTime = new Date(dateOfRide + " " + timeOfRide);

    return rideDateTime < referenceRideDateTime;
};


    

const place = Yup.object().shape({
    address: Yup.object().shape({
        street: Yup.string(),
        zip: Yup.string().nullable().matches(/^\d{4,5}$/,i18n.t("invalid zip")),
        city: Yup.string(),
        country: Yup.string().nullable(),
    })
})

const requiredPlace = Yup.object().shape({
    address: Yup.object().shape({
        street: Yup.string().required(i18n.t("field required", { field: i18n.t("street") })),
        zip: Yup.string().required(i18n.t("field required", { field: i18n.t("zip") })).matches(/^\d{4,5}$/,i18n.t("invalid zip")),
        city: Yup.string().required(i18n.t("field required", { field: i18n.t("city") })),
        country: Yup.string().nullable(),
    })
})

const contact = Yup.object().shape({
    firstName: Yup.string().nullable(),
    lastName: Yup.string().nullable(),
    phone: Yup.string().nullable(),
    email: Yup.string().email(i18n.t("invalid email")).nullable(),
})

const businessHours = Yup.array()
    .test({
        message: i18n.t("business hours may not be empty"),
        test: (value) => Array.isArray(value) && value.length > 0
    })
    .of(Yup.object().shape({
        from: Yup.string()
            .required(i18n.t("field required", { field: i18n.t("start of business hours") })),
        till: Yup.string()
            .required(i18n.t("field required", { field: i18n.t("end of business hours") }))
            .test({
                message: () => i18n.t("end of business hours not before start"),
                test: (value, { parent }) => {
                    return moment(value, "HH:mm").isSameOrAfter(moment(parent.from, "HH:mm"));
                },
            })
    }));
export const VALIDATION_SCHEMATA = {
    dynamicRideOrder: ({ additionalFormBlocks = {}, rideProvider, rideOrder, referenceRideDateTime }) => {
        const rideOrderShape = {
            endLocation: requiredPlace,
            lastName: Yup.string().required(i18n.t("field required", { field: i18n.t("lastname") })),
            firstName: Yup.string().required(i18n.t("field required", { field: i18n.t("firstname") })),
            startLocation: requiredPlace,
            rideType: Yup.object().required(i18n.t("field required", { field: i18n.t("rideType") })),
            reasonForLastChange: Yup.string().nullable(),
            dateOfRide: Yup.string().required(i18n.t("field required", { field: i18n.t("dateOfRide") }))
                .test('is-future-ride', i18n.t("ride time must be in the future"), isFutureRide),
            timeOfRide: Yup.string().required(i18n.t("field required", { field: i18n.t("timeOfRide") }))
                .test('is-future-ride', i18n.t("ride time must be in the future"), isFutureRide)
        };

        if (additionalFormBlocks[FORM_BLOCKS.dateOfBirth]) {
            rideOrderShape.dateOfBirth = Yup.date()
                .required(i18n.t("field required", { field: i18n.t("date of birth") }))
                .transform(parseDateString)
                .typeError(i18n.t('invalid date'));
        }

        if (additionalFormBlocks[FORM_BLOCKS.insuranceNumber]) {
            rideOrderShape.insuranceNumber = Yup.string().required(i18n.t("field required", { field: i18n.t("insuranceNumber") }));
        }

        if (additionalFormBlocks[FORM_BLOCKS.payerInformation]) {
            rideOrderShape.payerInformation = Yup.string().required(i18n.t("field required", { field: i18n.t("payerInformation") }));
        }
        if (additionalFormBlocks[FORM_BLOCKS.placeSpecifierComment]) {
            rideOrderShape.placeSpecifierComment = Yup.string().required(i18n.t("field required", { field: i18n.t("placeSpecifierComment") }));
        }

        if ((!isNaN(referenceRideDateTime)) && (!rideOrder?.rideOrderType || rideOrder?.rideOrderType === RideOrderType.RETURN)) {
            rideOrderShape.timeOfRide = rideOrderShape.timeOfRide.test('is-after-reference-ride',
                i18n.t("ride time must be after outward ride"),
                (value, context) => isAfterReferenceRide(value, { ...context, options: { referenceRideDateTime } })
            )
            rideOrderShape.dateOfRide = rideOrderShape.dateOfRide.test('is-after-reference-ride',
                i18n.t("ride time must be after outward ride"),
                (value, context) => isAfterReferenceRide(value, { ...context, options: { referenceRideDateTime } })
            )
        }

        if ((!isNaN(referenceRideDateTime)) && (rideOrder?.rideOrderType === RideOrderType.OUTWARD)) {
            rideOrderShape.timeOfRide = rideOrderShape.timeOfRide.test('is-before-reference-ride',
                i18n.t("ride time must be before return ride"),
                (value, context) => isBeforeReferenceRide(value, { ...context, options: { referenceRideDateTime } })
            )
            rideOrderShape.dateOfRide = rideOrderShape.dateOfRide.test('is-before-reference-ride',
                i18n.t("ride time must be before return ride"),
                (value, context) => isBeforeReferenceRide(value, { ...context, options: { referenceRideDateTime } })
            )
        }

        if (rideProvider) {
            rideOrderShape.timeOfRide = rideOrderShape.timeOfRide
                .test(
                    "withinBusinessHours",
                    i18n.t("ride time not within business hours"),
                    ((time, { parent }) => {
                        const dateTime = moment(parent.dateOfRide.toString() + ' ' + time.toString())
                        return rideProvider.isDateWithinBusinessHours(dateTime);
                    })
                )
                .test(
                    "",
                    i18n.t("ride time not within order limit"),
                    ((time, { parent }) => {
                        if (!rideOrder?.isNew) return true
    
                        const dateTime = moment(parent.dateOfRide.toString() + ' ' + time.toString())
                        return rideProvider.isRideDateWithinOrderLimit(dateTime)
                    })
                );
        }

        return Yup.object().shape(rideOrderShape);
    },
    rideOrderer: Yup.object().shape({
        name: Yup.string().required(i18n.t("field required", { field: i18n.t("orderer name") })),
        placeOfBusiness: place
    }),
    ipAddress: Yup.string()
        .matches(/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, i18n.t("invalid ip address")
        ),
    rideOrdererAdd: Yup.object().shape({
        name: Yup.string().required(i18n.t("field required", { field: i18n.t("orderer name") })),
    }),
    rideProvider: Yup.object().shape({
        placeOfBusiness: place.nullable(),
        companyName: Yup.string().required(i18n.t("field required", { field: i18n.t("company name") })),
        licenseNumber: Yup.string().required(i18n.t("field required", { field: i18n.t("license number") })),
        department: Yup.string().required(i18n.t("field required", { field: i18n.t("department") })),
        tenant: Yup.string().required(i18n.t("field required", { field: i18n.t("tenant") })),
        contact: contact.nullable(),
        businessHours: businessHours.nullable(),
    }),
    rideProviderAdd: Yup.object().shape({
        companyName: Yup.string().required(i18n.t("field required", { field: i18n.t("company name") })),
        licenseNumber: Yup.string().required(i18n.t("field required", { field: i18n.t("license number") })),
        department: Yup.string().required(i18n.t("field required", { field: i18n.t("department") })),
        tenant: Yup.string().required(i18n.t("field required", { field: i18n.t("tenant") })),
        apiKey: Yup.string().required(),
    }),
    user: Yup.object().shape({
        firstName: Yup.string().required(i18n.t("field required", { field: i18n.t("firstname") })),
        lastName: Yup.string().required(i18n.t("field required", { field: i18n.t("lastname") })),
        phone: Yup.string().required(i18n.t("field required", { field: i18n.t("phone") })),
        email: Yup.string().email(i18n.t("invalid email")).required(i18n.t("field required", { field: i18n.t("email") })),
    }),
    pageInformation: Yup.object().shape({
        imprint: Yup.string().nullable().url(i18n.t('correct url')),
        privacyPolicy: Yup.string().nullable().url(i18n.t('correct url')),
    })
}
