import {HttpController, RESPONSE_STRUCTURES} from "../../util/HttpController";

const controller = new HttpController("/api/ride/")

const rideOrderController = {
    order: (payload)=> controller.post('order', {...payload}),
    assignReturn: (payload)=> controller.post('assignReturn', {...payload}),
    getRideOrdersByUser: (payload)=> controller.post('getRideOrdersByUser', {...payload}, {responseStructure: RESPONSE_STRUCTURES.page}),
    getRideOrdersByRideOrderer: (payload)=> controller.post('getRideOrdersByRideOrderer', {...payload}, {responseStructure: RESPONSE_STRUCTURES.page}),
    getRideOrder: (payload)=> controller.post('getRideOrder', {...payload}),
    edit: (payload)=> controller.post('edit', {...payload}),
    cancel: (payload)=> controller.post('cancel', {...payload}),
    cancelRejected: (payload) => controller.post('cancelRejected',{...payload})
}

export default rideOrderController;