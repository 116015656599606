import {AVATAR_SIZES, AvatarButton, ButtonGroup, COLOR_CUSTOM_PROPERTIES, COLORS, IconButton, Table} from "@odm/ui";
import i18n from "../core/constants/i18n";
import {MdCheckCircle, MdEdit, MdHourglassFull, MdOutgoingMail, MdOutlineCancel} from "react-icons/md";
import {useDispatch} from "react-redux";
import {resendVerificationMail} from "../core/redux/slices/userSlice";
import getCssCustomProperty from "../util/getCssCustomProperty";
import {toast} from "react-toastify";

const UsersTable = ({users, scope, onEdit, onDissolve,rideProviderId}) => {
    const dispatch = useDispatch()
    const handleResendInvite = (user) => {
        dispatch(resendVerificationMail({rideProviderId, userId:user.id})).then(() => {
            toast.success(i18n.t("resend invite successful"));
        }).catch(() => {
            toast.error(i18n.t("resend invite failed"));
        });
    }

    const buildActions = (user) => (
        <ButtonGroup>
            <IconButton
                id="icon-button_edit-user"
                title={scope? i18n.t(`edit ${scope}`) : i18n.t("edit user")}
                onClick={()=>onEdit(user)}
                icon={<MdEdit/>}/>
            <IconButton
                id="icon-button_remove-user"
                title={scope? i18n.t(`remove ${scope}`) : i18n.t("remove user")}
                onClick={()=>onDissolve(user)}
                icon={<MdOutlineCancel/>}/>
            {
                user.hasLoggedIn
                ? null
                : <IconButton
                    id={"icon-button_resend_invite"}
                    title={i18n.t("resend invite")}
                    onClick={()=> handleResendInvite(user)}
                    icon={<MdOutgoingMail />}
                  />
            }
        </ButtonGroup>
    )

    const columns = [
        {
            Header: " ",
            accessor: (user)=>(
              <AvatarButton size={AVATAR_SIZES.sm}
                            user={user}
                            badge={
                                user.hasLoggedIn
                                    ? <MdCheckCircle color={getCssCustomProperty(COLOR_CUSTOM_PROPERTIES[COLORS.signalSuccess])}/>
                                    : <MdHourglassFull color={getCssCustomProperty(COLOR_CUSTOM_PROPERTIES[COLORS.signalWarning])}/>

                            }
              />
            )
        },
        {
            Header: i18n.t("email"),
            accessor: (user)=>user.email
        },
        {
            Header: i18n.t("firstname"),
            accessor: (user)=>user.firstName
        },
        {
            Header: i18n.t("lastname"),
            accessor: (user)=>user.lastName
        },
        {
            Header: i18n.t("actions"),
            accessor: (user)=> buildActions(user)
        }
    ]

    return (
        <Table columns={columns} data={users}/>
    )
}

export default UsersTable