import {HttpController, RESPONSE_STRUCTURES} from "../../util/HttpController";
import {getRideOrderer} from "../../redux/slices/rideOrdererSlice";

const controller = new HttpController('/api/orderer/');

const rideOrdererController = {
    getRideOrderers:           (payload, config) => controller.post('getRideOrderers',payload,{responseStructure: RESPONSE_STRUCTURES.page, ...config}),
    getRideOrderer:            (payload, config) => controller.post('getRideOrderer',payload, config),
    edit:                       (payload, config) => controller.post('edit',payload,config),
    dissolve:                   (payload, config) => controller.post('dissolve',payload,config),
    add:                     (payload, config) => controller.post('add',payload,config),
}

export default rideOrdererController;
