import {Button, BUTTON_EMPHASIS, BUTTON_VARIANTS, ControlDecorator, InlineError, Input, Modal} from "@odm/ui";
import {Field, Formik, getIn} from "formik";
import i18n from "../core/constants/i18n";
import RideOrderer from "../core/models/RideOrderer";
import {define} from "../core/redux/slices/rideOrdererSlice";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import {VALIDATION_SCHEMATA} from "../core/constants/validationSchemata";
import {toast} from "react-toastify";

const fieldNames = {
    name: "name"
}

const RideOrdererAddDialog = ({isOpen,onClose, rideProviderId})=> {

    const [dispatchDefine,error] = useDispatchAsync(define);

    const handleCreate = (values) => {
        return dispatchDefine(values).then((res)=>{
            toast.success(i18n.t("create orderer successful"));
            onClose(new RideOrderer(res.payload));
        })
    }

    return (
        <Formik initialValues={RideOrderer.fromDefaultValues({rideProviderId}).formValues()}
                enableReinitialize={true}
                validateOnChange={true}
                onSubmit={(values)=>handleCreate(values)}
                validationSchema={VALIDATION_SCHEMATA.rideOrdererAdd}
        >
            {({submitForm,touched,errors,dirty,isValid,resetForm}) => (
                <Modal isOpen={isOpen}
                       onClose={onClose}
                       title={i18n.t("add ride orderer")}
                       actionsSlot={<>
                           <Button title={i18n.t("cancel")}
                                   id="button_cancel"
                                   variant={BUTTON_VARIANTS.cancel}
                                   emphasis={BUTTON_EMPHASIS.low}
                                   onClick={()=> {
                                       resetForm()
                                       onClose()
                                   }}
                           />
                           <Button title={i18n.t("create")}
                                   id="button_create-ride-orderer"
                                   variant={BUTTON_VARIANTS.add}
                                   emphasis={BUTTON_EMPHASIS.high}
                                   disabled={!dirty || !isValid}
                                   onClick={submitForm}
                           />
                       </>}
                >
                    <ControlDecorator label={i18n.t("name")}
                                      error={getIn(touched,fieldNames.name) && getIn(errors,fieldNames.name)}
                    >
                        <Field as={Input} id="input_ride-orderer-name" name={fieldNames.name}/>
                    </ControlDecorator>
                    <InlineError errorMessage={error && i18n.t("rideOrderer add rejected")}/>
                </Modal>
            )}
        </Formik>
    )
}

export default RideOrdererAddDialog;