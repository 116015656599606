import React from "react";
import {Entity} from "./Entity";
import {UserRole} from "./UserRole";

export class LocalUser extends Entity {

  static selectId = (localUser) => localUser.localUserId;
  static schema = {
    localUserId: [String],
    firstName: [String],
    lastName: [String],
    email: [String],
    username: [String],
    rideProviderId: [String],
    rideOrdererId: [String],
    userId: [String],
    role: [UserRole]
  };

  static fromKeycloakUserinfo(userinfo) {

    const {
      given_name: firstName,
      family_name: lastName,
      preferred_username: username,
      sub: userId,
      ...others
    } = userinfo;

    return new LocalUser({
      firstName,
      lastName,
      username,
      userId,
      ...others
    })
  }

  get hasAdminPrivilege() {
    return this.role?.value === UserRole.SUPER_ADMIN || this.role?.value === UserRole.ADMIN
  }

  get isAdmin() {
    return this.role?.value === UserRole.ADMIN
  }

  get isSuperAdmin() {
    return this.role?.value === UserRole.SUPER_ADMIN
  }

  /**
   * Returns if this user has at least the privileges of given user role:
   * USER < ADMIN < SUPER_ADMIN
   */
  hasAtLeastRole(role) {
    if (!role) {
      return false;
    }

    switch (role) {
      case UserRole.USER:
        return true;
      case UserRole.ADMIN:
        return this.role?.value === UserRole.ADMIN || this.role?.value === UserRole.SUPER_ADMIN;
      case UserRole.SUPER_ADMIN:
        return this.role?.value === UserRole.SUPER_ADMIN;
    }
  }

  constructor(data = {}) {
    super(data)
  }
}

export default Entity.applySchema(LocalUser);