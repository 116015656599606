import React from "react";
import {CONTROL_APPEARANCE, ControlDecorator, Input} from "@odm/ui";
import {Field, getIn, useFormikContext} from "formik";
import {BiIdCard} from "react-icons/bi";
import i18n from "../core/constants/i18n";

const InsuranceNumberFormSection = () => {

  const {touched, errors, isSubmitting} = useFormikContext();

  return <ControlDecorator icon={<BiIdCard/>}
                           error={getIn(touched, "insuranceNumber") && getIn(errors, "insuranceNumber")}
                           label={i18n.t("insuranceNumber")}
                           labelFor={"insuranceNumber"}>
    <Field as={Input}
           id="input_insurance-number"
           name={"insuranceNumber"}
           disabled={isSubmitting}
           controlAppearance={CONTROL_APPEARANCE.filled}
           placeholder={i18n.t("insurance number placeholder")}
    />
  </ControlDecorator>

}

export default InsuranceNumberFormSection;