import "./CmRideTypeIcon.scss";
import {TbVirus} from "react-icons/tb";

const CmRideTypeIcon = ({value,infected}) => (
    <div className={"cm-ride-type-icon"}>
        {infected && <div className={"cm-ride-type-icon__infected"}>{<TbVirus/>}</div>}
        <svg className={"cm-ride-type-icon__icon"} viewBox="0 0 24 24">
            <rect x="1" y="1" rx="3" ry="3" width="22" height="22"
                  style={{fill: "none",strokeWidth:1.75}}
            />
            <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">{value?.number ?? "?"}</text>
        </svg>
    </div>
)

export default CmRideTypeIcon;