import {EntitySliceHelper} from "../../util/EntitySliceHelper";
import User from "../../models/User";
import userController from "../../http/controller/userController";
import {createSelector} from "@reduxjs/toolkit";
import {UserState} from "../../models/UserState";
import {UserRole} from "../../models/UserRole";
import {localUserSelector} from "./localUserSlice";

const helper = new EntitySliceHelper("user",User);

export const {  selectAll: selectUsers }= helper.getSelectors();

export const selectUndissolvedUsers = createSelector(
    [selectUsers],
    (users)=>users.filter(user => {
        return user.userState.value !== UserState.DISSOLVED

    })
)

export const selectUndissolvedUsersByRideOrdererId = createSelector(
    [selectUndissolvedUsers,(state,rideOrdererId)=>rideOrdererId],
    (users,rideOrdererId) => users.filter(user=>user.rideOrdererId === rideOrdererId)
)

export const selectUndissolvedUsersByRideProviderId = createSelector(
    [selectUndissolvedUsers,(state,rideProviderId)=>rideProviderId],
    (users,rideProviderId) => users.filter(user=>user.rideProviderId === rideProviderId)
)

export const selectAdminUsersByRideProvider = createSelector(
    selectUndissolvedUsersByRideProviderId,
    (users) => {
        return users.filter(user => user.role.value === UserRole.ADMIN)
    }
)

export const selectCurrentLocalUser = createSelector(
    [selectUsers,localUserSelector],
    (users,localUser) => users.find((user)=> user.userId === localUser.userId)
  )

export const getUsers = helper.createAsyncAction(userController.getUsers);
export const getUser = helper.createAsyncAction(userController.getUser);
export const edit = helper.createAsyncAction(userController.edit);
export const dissolve = helper.createAsyncAction(userController.dissolve);
export const add = helper.createAsyncAction(userController.add);
export const resendVerificationMail = helper.createAsyncAction(userController.resendVerificationMail);

// TODO: mostly boilerplate, check before usage
const userSlice = helper.createSlice({
    extraReducers: builder => {
        builder.addCase(getUsers.fulfilled, (state,action)=>{
            helper.adapter.upsertMany(state,action.payload)
        })
        builder.addCase(getUser.fulfilled, (state,action)=>{
            helper.adapter.upsertOne(state,action.payload)
        })
        builder.addCase(edit.fulfilled, (state,action)=>{
            helper.adapter.upsertOne(state,action.payload)
        })
        builder.addCase(dissolve.fulfilled, (state,action)=>{
            helper.adapter.upsertOne(state,action.payload)
        })
        builder.addCase(add.fulfilled, (state, action)=>{
            helper.adapter.upsertOne(state,action.payload)
        })
    }
})

export default userSlice;