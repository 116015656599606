import React, { useState } from "react";
import i18n from "../core/constants/i18n";
import {
  DynamicGrid,
  DynamicRow,
  IconButton,
  Input,
  ICON_SIZES,
  EmptyState,
  InlineError,
  Table,
} from "@odm/ui";
import { useFormikContext } from "formik";
import { MdAdd, MdOutlineCancel } from "react-icons/md";
import { TbWorldPin } from "react-icons/tb";
import { VALIDATION_SCHEMATA } from "../core/constants/validationSchemata";
import "./IPFormSection.scss";

const IPFormSection = () => {
  const { values, setFieldValue } = useFormikContext();
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isValid, setIsValid] = useState(false);
  const validationSchema = VALIDATION_SCHEMATA.ipAddress;

  const buildActions = (ip) => (
    <IconButton type="button" id="icon-button_remove-ip-address" onClick={() => handleRemoveItem(ip)} icon={<MdOutlineCancel />} />
  );

  const columns = [
    {
      Header: i18n.t("ip address"),
      accessor: (ip) => ip,
    },
    {
      Header: i18n.t("actions"),
      accessor: (ip) => buildActions(ip),
    },
  ];

  const handleInputChange = (event) => {
    const ip = event.target.value;
    setInputValue(ip);
    validationSchema.isValid(ip).then((valid) => {
      setIsValid(valid);
    });
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleInputChange(event);
      handleAddItem();
    }
  };

  const handleAddItem = () => {
    if (inputValue.trim() === "" || !isValid) {
      setErrorMessage(i18n.t("invalid ip address"));
      return;
    }

    const addressList = Array.isArray(values?.ipWhitelist?.addressList)
      ? values.ipWhitelist.addressList
      : [];

    if (addressList.includes(inputValue)) {
      setErrorMessage(i18n.t("duplicated ip address"));
      return;
    }

    setFieldValue("ipWhitelist", {
      addressList: [...addressList, inputValue],
    });
    setInputValue("");
    setErrorMessage("");
    setIsValid(false);
  };


  const handleRemoveItem = (ip) => {
    if (Array.isArray(values?.ipWhitelist?.addressList)) {
      setFieldValue(
        "ipWhitelist",
        {
          addressList: values.ipWhitelist.addressList.filter((item) => item !== ip),
        }
      );
    }
  };

  return (
    <fieldset>
      <DynamicGrid numCols={2}>
        <DynamicRow>
          <Input
            type="text"
            name={"ipInputField"}
            id={"input_ip-address"}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleEnterKeyPress}
            placeholder={i18n.t("ip address input placeholder")}
          />
          <IconButton
            icon={<MdAdd />}
            id={"icon-button_add-ip-address"}
            size={ICON_SIZES.sm}
            type="button"
            name={"ipInputButton"}
            disabled={!isValid}
            className="ip-form-button"
            onClick={handleAddItem}
          />
        </DynamicRow>
      </DynamicGrid>
      <InlineError errorMessage={errorMessage} />

      <DynamicRow className="ip-list-container">
        {!values?.ipWhitelist?.addressList?.length ? (
          <EmptyState
            title={i18n.t("no ip title")}
            description={i18n.t("no ip description")}
            icon={<TbWorldPin />}
          />
        ) : <Table columns={columns} data={values?.ipWhitelist?.addressList} />}
      </DynamicRow>
    </fieldset>
  );
};

export default IPFormSection;
