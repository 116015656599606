import {createSelector, createSlice} from "@reduxjs/toolkit";
import LocalUser from "../../models/LocalUser";

const sliceName = "localUser";

const sliceSelector = (state) => state[sliceName];
export const localUserSelector = createSelector([sliceSelector], (slice) => {
  return LocalUser.fromKeycloakUserinfo(slice);
});

const localUserSlice = createSlice({
  name: sliceName,
  initialState: {},
  reducers: {
    set: (state, action) => {
      return action.payload;
    }
  }
})

export default localUserSlice;