import React from "react";
import {
  DynamicGrid,
  DynamicRow,
  DynamicRowElement
} from "@odm/ui";
import i18n from "../core/constants/i18n";
import ImageUpload from "../components/ImageUpload";

const RideProviderCustomizationSection = ({rideProvider}) => {

  /** These need to match the 'ImagePlacement' enum on the backend */
  const fieldNames = {
    heroImage: "hero",
    logoImage: "logo",
  }

  return <DynamicGrid numCols={2}>
    <DynamicRow>
      <DynamicRowElement colSpan={1}>
        <ImageUpload
          label={i18n.t("hero image")}
          id="image-upload_hero-image"
          name={fieldNames.heroImage}
          rideProvider={rideProvider}
        />
        {rideProvider?.pageInformation?.heroImageUrl ? <img
          src={rideProvider?.pageInformation?.heroImageUrl}
          alt={"hero"}
        /> : null}

      </DynamicRowElement>
      <DynamicRowElement colSpan={1}>
        <ImageUpload
          label={i18n.t("logo image")}
          id="image-upload_logo-image"
          name={fieldNames.logoImage}
          rideProvider={rideProvider}
        />
        {rideProvider?.pageInformation?.logoImageUrl ? <img
          src={rideProvider?.pageInformation?.logoImageUrl}
          alt={"logo"}
        /> : null}
      </DynamicRowElement>
    </DynamicRow>
  </DynamicGrid>
}

export default RideProviderCustomizationSection;