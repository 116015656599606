import {
  BodyText,
  Button,
  BUTTON_VARIANTS,
  FlexRow,
  Modal,
  TitleText
} from "@odm/ui";
import i18n from "../core/constants/i18n";
import {MdPhone} from "react-icons/md";

const RideOrderActionPreventionDialog = ({
  rideProvider,
  isOpen,
  onClose,
  titleText,
  descriptionText,
}) => {
    const phoneNumber = rideProvider?.contact?.phone

    return (
        <Modal isOpen={isOpen}
               onClose={onClose}
               title={titleText}
               actionsSlot={
                <>
                   <Button title={i18n.t("confirm")}
                           id="button_confirm"
                           variant={BUTTON_VARIANTS.confirm}
                           onClick={onClose}
                   />
                </>
               }

        >
            <BodyText>{descriptionText}</BodyText>
          {!!phoneNumber
            ? <>
                <BodyText>{i18n.t("contact us by telephone number")}</BodyText>
                <a href={`tel:${phoneNumber}`}><FlexRow align={"center"}><MdPhone/><TitleText>{phoneNumber}</TitleText></FlexRow></a>
              </>
            : null
          }
        </Modal>
    )
}
export default RideOrderActionPreventionDialog;
