import React from "react";
import {Field, Formik, getIn} from "formik";
import {
  BodyText,
  Button,
  BUTTON_VARIANTS,
  ControlDecorator,
  DynamicGrid,
  DynamicRow,
  DynamicRowElement,
  InlineError,
  Input, PageSection
} from "@odm/ui";
import PageInformation from "../core/models/PageInformation";
import i18n from "../core/constants/i18n";
import {BlackPortal} from "react-native-portal";
import portalNames from "../core/constants/portalNames";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import {editPageInformation} from "../core/redux/slices/rideProviderSlice";
import {VALIDATION_SCHEMATA} from "../core/constants/validationSchemata";
import {toast} from "react-toastify";
import FormErrorIcon from "../components/FormErrorIcon";
import NarrowContainer from "../components/NarrowContainer";
import OrderCustomizationFormSection from "./OrderCustomizationFormSection";

const PageInformationForm = ({rideProvider}) => {

  const defaultValues = PageInformation.formValues();
  const [dispatchEditPageInformation, editError] = useDispatchAsync(editPageInformation);

  const onSubmit = (values, {setSubmitting}) => {

    values.additionalFormBlocks = {
      ...defaultValues.additionalFormBlocks,
      ...values.additionalFormBlocks,
    }

    dispatchEditPageInformation({
      rideProviderId: rideProvider.rideProviderId,
      ...values
    }).then(() => {
      toast.success(i18n.t("edit successful"));
    }).catch((e) => {
      toast.error(i18n.t("edit failed"));
    }).finally(() => {
      setSubmitting(false);
    })
  }

  const fieldNames = {
    primaryColor: "primaryColor",
    secondaryColor: "secondaryColor",
    heading: "heading",
    subtitle: "subtitle",
    privacyPolicy: "privacyPolicy",
    imprint: "imprint",
    additionalFormBlocks: "additionalFormBlocks",
  }

  const initialValues = {
    ...defaultValues,
    ...rideProvider?.pageInformation?.formValues(),
  }

  return <Formik
      validateOnChange={true}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validationSchema={VALIDATION_SCHEMATA.pageInformation}
  >

    {({
      submitForm,
      dirty,
      isValid,
      isSubmitting,
      errors
    }) => {

      return <form>
        <InlineError errorMessage={editError && i18n.t("pageInformation edit rejected")}/>
        <DynamicGrid numCols={2}>
          <DynamicRow>
            <DynamicRowElement colSpan={1}>
              <ControlDecorator label={i18n.t("primary color")}>
                <Field as={Input} id="input_primary-color" type={"color"} name={fieldNames.primaryColor}/>
              </ControlDecorator>
            </DynamicRowElement>
            <DynamicRowElement colSpan={1}>
              <ControlDecorator label={i18n.t("secondary color")}>
                <Field as={Input} id="input_secondary-color" type={"color"} name={fieldNames.secondaryColor}/>
              </ControlDecorator>
            </DynamicRowElement>
          </DynamicRow>
          <DynamicRow>
            <DynamicRowElement colSpan={1}>
              <ControlDecorator label={i18n.t("ride provider heading")}>
                <Field as={Input} id="input_ride-provider-heading" name={fieldNames.heading}/>
              </ControlDecorator>
            </DynamicRowElement>
            <DynamicRowElement colSpan={1}>
              <ControlDecorator label={i18n.t("ride provider subtitle")}>
                <Field as={Input} id="input_ride-provider-subtitle" name={fieldNames.subtitle}/>
              </ControlDecorator>
            </DynamicRowElement>
          </DynamicRow>
          <DynamicRow>
            <DynamicRowElement colSpan={2}>
              <ControlDecorator
                  label={i18n.t("ride provider imprint")}
                  error={getIn(errors, fieldNames.imprint)}
              >
                <Field
                    id="input_imprint"
                    as={Input}
                    type={'url'}
                    name={fieldNames.imprint}
                    placeholder={i18n.t('httpImprint')}
                />
              </ControlDecorator>
            </DynamicRowElement>
            <DynamicRowElement colSpan={2}>
              <ControlDecorator
                  label={i18n.t("ride provider privacy policy")}
                  error={getIn(errors, fieldNames.privacyPolicy)}
              >
                <Field
                    id="input_privacy-policy"
                    as={Input}
                    type={'url'}
                    name={fieldNames.privacyPolicy}
                    placeholder={i18n.t('httpPrivacyPolicy')}
                />
              </ControlDecorator>
            </DynamicRowElement>
          </DynamicRow>
        </DynamicGrid>

        <PageSection id={'customize-order-form'} title={i18n.t("order form")}>
          <NarrowContainer>
            <BodyText>{i18n.t("customize global order form description")}</BodyText>
            <PageSection>
              <OrderCustomizationFormSection/>
            </PageSection>
          </NarrowContainer>
        </PageSection>

        <BlackPortal name={portalNames.headerBarAction}>
          <FormErrorIcon dirty={dirty} isValid={isValid}/>
          <Button title={i18n.t("save changes")}
                  id="button_save"
                  variant={BUTTON_VARIANTS.save}
                  loading={isSubmitting}
                  disabled={!dirty}
                  onClick={submitForm}
          />
        </BlackPortal>
      </form>
    }}
  </Formik>
}

export default PageInformationForm;