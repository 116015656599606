import React from "react";
import {Field, getIn, useFormikContext} from "formik";
import {Checkbox, ControlDecorator} from "@odm/ui";
import i18n from "../core/constants/i18n";
import {IoScaleOutline} from "react-icons/io5";

const OverweightFormSection = () => {
  const {values} = useFormikContext();

  return <ControlDecorator icon={<IoScaleOutline/>}>
    <Field as={Checkbox}
           id="checkbox_overweight"
           name={"isOverweight"}
           label={i18n.t("isOverweightLabel")}
           checked={getIn(values,"isOverweight")}
    />
  </ControlDecorator>
}

export default OverweightFormSection;