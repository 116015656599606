import {
  Button,
  BUTTON_EMPHASIS,
  BUTTON_VARIANTS,
  ButtonGroup,
  ContentContainer,
  EmptyState,
  InlineError,
  MenuEntry,
  PageHeader,
  PageSection,
  SectionError,
  Dropdown, DROPDOWN_POSITIONS,
} from "@odm/ui";
import i18n from "../core/constants/i18n";
import UsersTable from "../components/UsersTable";
import {useSelector} from "react-redux";
import {getUsers, selectUndissolvedUsersByRideOrdererId} from "../core/redux/slices/userSlice";
import {edit, selectRideOrdererById} from "../core/redux/slices/rideOrdererSlice";
import React, {useEffect, useState} from "react";
import UserDissolveModal from "../components/UserDissolveModal";
import UserAddModal from "../components/UserAddModal";
import UserEditModal from "../components/UserEditModal";
import RideOrdererForm from "../forms/RideOrdererForm";
import RideOrdererDissolveModal from "../components/RideOrdererDissolveModal";
import {UserRole} from "../core/models/UserRole";
import {MdDelete} from "react-icons/md";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import {routeParameter} from "../core/constants/routes";
import {useNavigate, useParams} from "react-router-dom";
import RideOrdererAddDialog from "../components/RideOrdererAddDialog";
import {toast} from "react-toastify";
import portalNames from "../core/constants/portalNames";
import {WhitePortal} from "react-native-portal";
import {FiMoreHorizontal} from "react-icons/fi";
import {FaUserSlash} from "react-icons/fa";
import NarrowContainer from "../components/NarrowContainer";
import ScrollableContent from "../components/ScrollableContent";

const modals = {
  addUser: "addUser",
  dissolveUser: "dissolveUser",
  editUser: "editUser",
  dissolveRideOrderer: "dissolveRideOrderer"
}

const RideOrdererPage = () => {
  const {[routeParameter.rideProviderId]: rideProviderId} = useParams()
  const {[routeParameter.rideOrdererId]: rideOrdererId} = useParams()
  const [addRideOrdererOpen, setAddRideOdererOpen] = useState(false)

  const [openModal, setOpenModal] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const [dispatchGetUsers, usersError] = useDispatchAsync(getUsers);
  const [dispatchEdit, editError] = useDispatchAsync(edit);
  const users = useSelector((state) => selectUndissolvedUsersByRideOrdererId(state, rideOrdererId))
  const rideOrderer = useSelector((state) => selectRideOrdererById(state, rideOrdererId))

  const navigate = useNavigate();

  useEffect(() => {
    dispatchGetUsers({
      rideOrdererId,
      rideProviderId
    })
  }, [rideOrdererId])

  const handleEditRideOrderer = (values) => {
    return dispatchEdit(values).then(() => {
      toast.success(i18n.t("edit successful"))
    })
  }

  const handleRideOrdererDissolved = () => {
    setOpenModal(null);
  }

  const openDissolveModal = (user) => {
    setCurrentUser(user);
    setOpenModal(modals.dissolveUser);
  }
  const openEditModal = (user) => {
    setCurrentUser(user);
    setOpenModal(modals.editUser);
  }
  const openAddModal = () => {
    setCurrentUser(null);
    setOpenModal(modals.addUser);
  }

  const buildUserTable = () => {
    if (users.length > 0) {
      return (
          <UsersTable users={users}
                      scope="user"
                      onDissolve={openDissolveModal}
                      onEdit={openEditModal}
                      rideProviderId={rideProviderId}
          />
      )
    } else if (!usersError) {
      return (
          <EmptyState title={i18n.t("no users title")}
                      description={i18n.t("no users description")}
                      icon={<FaUserSlash/>}
                      actions={<Button title={i18n.t("add user")}
                                       id="button_add-user"
                                       variant={BUTTON_VARIANTS.add}
                                       emphasis={BUTTON_EMPHASIS.high}
                                       onClick={openAddModal}
                      />}
          />
      )
    }
    return null;
  }

  return (
      <>
        <PageHeader title={rideOrderer?.name}
                    actions={<ButtonGroup>
                      <WhitePortal name={portalNames.headerBarAction}/>
                      <Dropdown position={DROPDOWN_POSITIONS.bottomEnd}
                                openOnHover={false}
                                trigger={(toggle) => <Button title={""}
                                                             id="button_dropdown-more"
                                                             onClick={toggle}
                                                             icon={<FiMoreHorizontal/>}
                                                             emphasis={BUTTON_EMPHASIS.low}
                                />
                                }>
                        <MenuEntry menuItem={{
                          label: i18n.t("dissolve rideOrderer"),
                          onClick: () => setOpenModal(modals.dissolveRideOrderer),
                          icon: <MdDelete/>,
                          id: "button_dissolve-ride-orderer"
                        }}/>
                      </Dropdown>
                    </ButtonGroup>
                    }
        />
        <ScrollableContent>
          <InlineError errorMessage={editError && i18n.t("rideOrderer edit rejected")}/>
          <ContentContainer>
            <NarrowContainer>
              <RideOrdererForm rideOrderer={rideOrderer} onSubmit={handleEditRideOrderer}/>
            </NarrowContainer>

            <PageSection title={i18n.t("users")}
                         actionsSlot={<Button title={i18n.t("add user")}
                                          id="button_add-user"
                                          variant={BUTTON_VARIANTS.add}
                                          emphasis={BUTTON_EMPHASIS.low}
                                          onClick={openAddModal}
                         />}
            >
              <SectionError errorMessage={usersError && i18n.t("get users rejected")}/>
              {buildUserTable()}
            </PageSection>

          </ContentContainer>
        </ScrollableContent>

        <UserDissolveModal isOpen={openModal === modals.dissolveUser}
                           onClose={() => setOpenModal(null)}
                           user={currentUser}
        />
        <UserAddModal isOpen={openModal === modals.addUser}
                      onClose={() => setOpenModal(null)}
                      partialInitialValues={{
                        rideOrdererId,
                        rideProviderId,
                        role: UserRole.USER.description
                      }}
        />
        <UserEditModal isOpen={openModal === modals.editUser}
                       onClose={() => setOpenModal(null)}
                       user={currentUser}
        />
        <RideOrdererDissolveModal rideOrderer={rideOrderer}
                                  onClose={handleRideOrdererDissolved}
                                  isOpen={openModal === modals.dissolveRideOrderer}
        />
        <RideOrdererAddDialog isOpen={addRideOrdererOpen}
                              rideProviderId={rideProviderId}
                              onClose={(currRideOrderer) => {
                                navigate(currRideOrderer.id)
                                setAddRideOdererOpen(false)
                              }}
        />
      </>

  )
}

export default RideOrdererPage