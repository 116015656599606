import React, {useEffect, useState} from "react";
import Color from "color";
import {routeParameter} from "../core/constants/routes";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectRideProviderById} from "../core/redux/slices/rideProviderSlice";
import {localUserSelector} from "../core/redux/slices/localUserSlice";

const odmTheme =   {
    theme: "odm",
    title: "Willkommen",
    subtitle: "Beim Transport-Bestellsystem der opta data motion.",
    heroImage: "https://www.optadata-motion.de/sites/default/files/styles/hero_header/public/2022-04/Kontakt.png.jpeg?itok=J3yp5FNR",
    logo: "https://www.optadata-motion.de/sites/default/files/2021-03/optadata.svg",
    primaryColor: "#2F6E80",
    secondaryColor: "#77C5B8"
};

const mockThemes = [
    odmTheme,
    {
        theme: "juh",
        title: "Johanniter Krankentransport",
        subtitle: "Sie brauchen einen Transport im Krankenwagen? Unser erfahrenes Rettungspersonal ist mit spezieller Technik und Medikamenten ausgestattet, um Ihnen volle Sicherheit und Komfort bei Fahrten zu geben.",
        heroImage: "https://assets.johanniter.de/fileadmin/_processed-local_/2/0/csm_johanniter-key-visual-ueber-die-johanniter_5aab253080.png",
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/Logo_der_Johanniter_Unfall-Hilfe.svg/2560px-Logo_der_Johanniter_Unfall-Hilfe.svg.png",
        primaryColor: "#000548",
        secondaryColor: "#EB003C"
    },
    {
        theme: "minicar",
        title: "MINICAR KASSEL",
        subtitle: "Krankenfahrt / Dialysefahrt – günstig, sicher, zuverlässig in Kassel",
        heroImage: "https://minicar-kassel.de/wp-content/uploads/minicar-citycar-personenbefoerderung-kassel-guenstiger-als-taxi-40.jpeg",
        logo: "https://minicar-kassel.de/wp-content/uploads/minicar_kassel_logo.png",
        primaryColor: "#000000",
        secondaryColor: "#C00C1B"
    },
    {
        theme: "sanitrans",
        title: "Sani Trans Ahlen",
        subtitle: "Ihr kompetenter und preisgünstiger Anbieter für Krankenfahrten in Ahlen, Beckum, Drensteinfurt, Sendenhorst und Hamm.",
        heroImage: "http://www.sanitrans-ahlen.de/wp-content/uploads/2015/09/Sanitrans-Foto-04-2014_1-880x587.jpg",
        logo: "http://www.sanitrans-ahlen.de/wp-content/themes/genesis-sample/img/logo.png",
        primaryColor: "#36287D",
        secondaryColor: "#FD8727"
    },
]

export const ThemeContext = React.createContext(null);

const ThemeContextProvider = ({children}) => {

    const [theme] = useState(odmTheme);
    const {[routeParameter.rideProviderId]: pathRideProviderId} = useParams();
    const localUser = useSelector(localUserSelector);
    const {rideProviderId: userRideProviderId} = localUser;

    // For super-admins who are not directly linked to a ride provider, the respective path's ID
    // applies. For all other users, their own ride provider ID applies.
    const rideProviderId = userRideProviderId || pathRideProviderId;

    const rideProvider = useSelector((state) => selectRideProviderById(state, rideProviderId));

    const updateColors = ({primaryColor = odmTheme.primaryColor, secondaryColor = odmTheme.secondaryColor}) => {

        let primary, secondary;
        try {
            primary = Color(primaryColor)
            secondary = Color(secondaryColor)
        } catch (e) {
            primary = Color(odmTheme.primaryColor)
            secondary = Color(odmTheme.secondaryColor)
            console.warn("Could not parse colors: " + primaryColor + ", " + secondaryColor)
        }

        const root = document.querySelector(":root")

        root.style.setProperty('--odm-primary', primary.hex());
        root.style.setProperty('--odm-primary-hover', primary.darken(0.1));
        root.style.setProperty('--odm-primary-focus', primary.lighten(0.1));
        root.style.setProperty('--odm-primary-faded', primary.alpha(0.1));
        root.style.setProperty('--odm-secondary', secondary.hex());
        root.style.setProperty('--odm-elem-active', secondary.alpha(0.2));
        root.style.setProperty('--odm-text-on-primary', primary.mix(Color("white"), 0.9));
        root.style.setProperty('--odm-text-on-secondary', secondary.mix(Color("white"), 0.9));
    }

    useEffect(() => {
        updateColors({
            primaryColor: rideProvider?.pageInformation?.primaryColor,
            secondaryColor: rideProvider?.pageInformation?.secondaryColor,
        })
    }, [rideProvider]);

    return (
        <ThemeContext.Provider value={theme}>
            {children}
        </ThemeContext.Provider>
    )
}

export default ThemeContextProvider;

