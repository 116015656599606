import "./TimePicker.scss";

import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import { isInteger, maxBy, noop, throttle} from "lodash";
import classNames from "classnames";
import {MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp} from "react-icons/md";


// TODO handle falsy / undefined values
// TODO add possibility to disable selection of certain time values

const TimerPicker = (
    {
         value,
         onChange
    })=> {

    const hoursRef = useRef();
    const minutesRef = useRef();
    const valueRef = useRef(value)

    useEffect(()=>{
        valueRef.current = value;
    },[value])


    const setHours = (hours) => {
        onChange([hours, valueRef.current.minutes,"00"].join(":"))
    }

    const setMinutes = (minutes) => {
        onChange([valueRef.current.hours,minutes,"00"].join(":"))
    }


    const setNextFullHour = () => {
        onChange([+valueRef.current.hours + 1,0])
    }

    return (
        <div className={"time-picker"}>
            <TimePickerSlot length={24}
                            id="time-picker-slot_hours"
                            onChange={setHours}
                            value={value.hours}
                            ref={hoursRef}
                            focusNextSlot={()=>minutesRef.current.focus()}
            />
            <span>:</span>
            <TimePickerSlot length={12}
                            id="time-picker-slot_minutes"
                            step={5}
                            onChange={setMinutes}
                            value={value.minutes}
                            ref={minutesRef}
                            focusPrevSlot={()=>hoursRef.current.focus()}
                            addToPrevSlot={setNextFullHour}
            />
        </div>

    )
}

export default TimerPicker;

const TimePickerSlot = forwardRef(({length,step=1,onChange, value, addToPrevSlot, focusPrevSlot, focusNextSlot},ref) => {
    const contentRef = useRef(null);
    const wheelRef = useRef(null);
    const slotRef = useRef(null);
    const valueRef = useRef(value);
    const inputRef = useRef(null);

    useImperativeHandle(ref,()=>({
        focus: ()=> inputRef.current.focus()
    }))

     useEffect(() => {
        let digit = getRoundedValue();

        if(digit >= length * step){
             digit =  length * step - step;
         }

        const el = contentRef.current.querySelector(`[data-digit="${digit}"]`)

        const top = el.offsetTop - (slotRef.current.offsetHeight * 0.5) + (el.offsetHeight * 0.5)

        valueRef.current = digit;
        wheelRef.current.scrollTop = top;

     },[value]);

    useEffect(()=>{
        const intersectionObserver = new IntersectionObserver((entries) => {
            const focussedEntry = maxBy(entries.filter(e => e.isIntersecting),(e)=>e.intersectionRatio)

            if(focussedEntry) {
                const {target} = focussedEntry;
                const height = contentRef.current.offsetHeight;

                if (target.offsetTop <= height || target.offsetTop >= height * 2 ) {
                    wheelRef.current.scrollTop = height + (target.offsetTop % height)  - (slotRef.current.offsetHeight * 0.5) + (target.offsetHeight * 0.5);
                }
                onChange(+target.dataset.digit);
            }
        },
        {root: slotRef.current, threshold: 0.6}
        )
        wheelRef.current.querySelectorAll('[data-digit]').forEach((el)=>intersectionObserver.observe(el))


        wheelRef.current.addEventListener('wheel',onWheel)

        return ()=> {
            wheelRef.current?.removeEventListener('wheel',onWheel)
            intersectionObserver.disconnect();
        }
    },[])

    const onWheel = (e) => {
        e.preventDefault();
        inputRef.current.blur()
        handleWheel(e)
    }
    const handleWheel = throttle((e)=>e.deltaY > 0 ? nextDigit() : prevDigit(),100)

    const getRoundedValue = ()=> Math.round(value / step) * step;

    const nextDigit = () => {
        const nextValue = valueRef.current + step;

        if(nextValue >= length * step){
            onChange(0)
        }else{
            onChange(nextValue)
        }
    }

    const prevDigit = () => {
        const prevValue = valueRef.current - step;

        if(prevValue < 0){
            onChange(length * step - step)
        }else{
            onChange(prevValue)
        }
    }
    const [tensDigit,setTensDigit] = useState("");

    const handleDigitInput = (e)=>{

        if(e.key === "ArrowDown") return nextDigit()
        if(e.key === "ArrowUp") return prevDigit()
        if(e.key === "ArrowRight") {
            tensDigit !== "" ? focusNextSlot?.() : setTensDigit(String(getRoundedValue())[0])
        }
        if(e.key === "ArrowLeft") {
            tensDigit === "" ? focusPrevSlot?.() : setTensDigit("")
        }

        if(!isInteger(+e.key)) return;

        if(tensDigit === "" && +e.key <= +String(length*step)[0]) {
            if(Number(e.key+0) >= length*step) return;
            setTensDigit(e.key)
            onChange(Number(e.key+0))
        }else{
            const roundedValue = +tensDigit*10+Math.round(+e.key / step) * step

            if(roundedValue >= length*step ) {
                addToPrevSlot?.()
            }else{
                onChange(roundedValue)
            }
            setTensDigit("");
            focusNextSlot?.();
        }
    }

    const buildDigits = () => Array.from({length},(_,i)=> {
            const digit =  i * step
            const digitString = String(digit).padStart(2, '0')
            const active =  digit === value
            return   (
                <div data-digit={digit}
                     id={`data-digit-${digit}`}
                     key={digit}
                     className={classNames("digit",{active})}
                >
                    <span className={classNames({"current-position":active && tensDigit === ""})}>{digitString[0]}</span>
                    <span className={classNames({"current-position":active && tensDigit !== ""})}>{digitString[1]}</span>
                </div>
            )
        }
    )

    return (
        <div className="time-picker__slot"
             ref={slotRef}
        >
            <div
                className="time-picker__wheel"
                id="time-picker_wheel"
                ref={wheelRef}
                onClick={()=>inputRef.current.focus()}
            >
                 <input ref={inputRef}
                        id="input_number"
                        value={+value}
                        type={"number"}
                        step={step}
                        onKeyDown={handleDigitInput}
                        onChange={noop}
                        onBlur={()=>setTensDigit("")}
                 />
                 <div>{buildDigits()}</div>
                 <div ref={contentRef}>{buildDigits()}</div>
                 <div>{buildDigits()}</div>
            </div>
            <div className={"time-picker__slot_controls"}>
                <button id="button_prev-digit" tabIndex={-1} type={"button"} onClick={prevDigit}><MdOutlineKeyboardArrowUp/></button>
                <button id="button_next-digit" tabIndex={-1} type={"button"} onClick={nextDigit}><MdOutlineKeyboardArrowDown/></button>
            </div>
        </div>
    );
})