import React from "react";
import {Field, getIn, useFormikContext} from "formik";
import {Checkbox, ControlDecorator} from "@odm/ui";
import i18n from "../core/constants/i18n";
import {SiOxygen} from "react-icons/si";

const OxygenFormSection = () => {

  const {values} = useFormikContext()

  return <ControlDecorator icon={<SiOxygen/>}>
    <Field as={Checkbox} name={"oxygenRequired"}
           id="checkbox_over-required"
           label={i18n.t("oxygenRequiredLabel")}
           checked={getIn(values,"oxygenRequired")}
    />
  </ControlDecorator>
}

export default OxygenFormSection;