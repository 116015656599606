import React from "react";
import {Field, getIn, useFormikContext} from "formik";
import {BsCalendarDate} from "react-icons/bs";
import i18n from "../core/constants/i18n";
import {CONTROL_APPEARANCE, ControlDecorator, Input} from "@odm/ui";

const DateOfBirthFormSection = (props) => {
  const {touched, errors, isSubmitting} = useFormikContext();
  return <ControlDecorator icon={<BsCalendarDate/>}
                           label={i18n.t("date of birth")}
                           labelFor={"dateOfBirth"}
                           error={getIn(touched, "dateOfBirth") && getIn(errors, "dateOfBirth")}
  >
    <Field placeholder={i18n.t("date of birth")}
           id="input_date-of-birth"
           name={"dateOfBirth"}
           type={"date"}
           as={Input}
           disabled={isSubmitting}
           controlAppearance={CONTROL_APPEARANCE.filled}
    />
  </ControlDecorator>
}

export default DateOfBirthFormSection;