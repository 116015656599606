import {Entity} from "./Entity";
import ContactInformation from "./ContactInformation";
import Place from "./Place";
import {RideProviderState} from "./RideProviderState";
import {DispatchSystem} from "./DispatchSystem";
import BusinessHoursInformation from "./BusinessHoursInformation";
import PageInformation from "./PageInformation";
import EntityArray from "./EntityArray";
import moment from "moment";


class RideProvider extends Entity {
    static selectId = (rideProvider) => rideProvider.rideProviderId;

    static schema = {
        rideProviderId:         [String, { writable: false}],
        companyName:            [String],
        contact:                [ContactInformation],
        placeOfBusiness:        [Place, {nullable: false}],
        businessHours:          [EntityArray, {type:[BusinessHoursInformation]}],
        editable:               [Boolean],
        cancelable:             [Boolean],
        cancellationLimitDays:  [Number],
        orderLimitDays:         [Number],
        rideProviderState:      [RideProviderState, { writable: false, nullable: true }],
        dispatchSystem:         [DispatchSystem],
        licenseNumber:          [String],
        tenant:                 [String],
        department:             [String],
        apiKey:                 [String],
        arrivalTimeTypeAllowed: [Boolean, {writable: true}],
        pickupTimeTypeAllowed:  [Boolean, {writable: true}],
        pageInformation:        [PageInformation],
    };

    isDateWithinBusinessHours(date) {
        return this.businessHours.some((businessHourInformation)=> businessHourInformation.isValidForDate(date))
    }

    hasDateBusinessHours(date) {
        const weekDay = BusinessHoursInformation.mapIsoWeekDay(moment(date).isoWeekday());
        return this.businessHours.some((businessHourInformation)=> businessHourInformation.isValidForWeekDay(weekDay));
    }

    isRideDateCancelable(rideDate) {
        if(!this.cancellationLimitDays) return true;

        return this.isDateWithinBusinessDayLimit(rideDate,this.cancellationLimitDays)
    }

    isRideDateWithinOrderLimit(rideDate) {
        if(!this.orderLimitDays || this.orderLimitDays < 0) return true;

        return this.isDateWithinBusinessDayLimit(rideDate, this.orderLimitDays)
    }

    isDateWithinBusinessDayLimit(date,limit) {
        const nextDate = moment(date).startOf('day').subtract(1,'day');
        const daysUntilRideDate = nextDate.diff(moment().startOf('day').add(1,'day'),'days')
        let businessHourDays = 0;

        for(let i = 0; i <= daysUntilRideDate; i++) {
            if(this.hasDateBusinessHours(nextDate)) businessHourDays++;
            if(businessHourDays >= limit) return true;
            nextDate.subtract(1,"day")
        }
        return false
    }


    getClosestBusinessHour(date) {
        const nextDate = moment(date);

        for(let i = 0; i < 7; i++){
            const weekDay = BusinessHoursInformation.mapIsoWeekDay(nextDate.isoWeekday())

            const sortedWeekDayBusinessHours =
              this.businessHours
              .filter(timespan => {
                  if(!timespan.isValidForWeekDay(weekDay)) return false

                  if(i === 0) {
                      const {hours,minutes} = timespan.from;
                      return nextDate.isSameOrBefore(moment(nextDate).set({hours, minutes}))
                  }
                  return true
              })
              .sort((a,b)=> a.from.toDate()-b.from.toDate())

            if(sortedWeekDayBusinessHours[0]) {
                const {hours,minutes} = sortedWeekDayBusinessHours[0].from
                return nextDate.set({hours,minutes})
            }else{
                nextDate.add(1, 'day');
            }
        }
        return moment();
    }

    static formValues () {
        return {
            companyName: "",
            editable: true,
            cancelable: true,
            dispatchSystem: new DispatchSystem(DispatchSystem.CAREMAN.description),
            licenseNumber: "",
            businessHours: [BusinessHoursInformation.formValuesAlways()],
            tenant: "M1",
            department: "Standard",
            apiKey: "",
            contact:{},
            cancellationLimitDays: 0
        }
    }

    constructor(data={}) {
        super(data);
    }
}

export default Entity.applySchema(RideProvider);