import i18n from "../core/constants/i18n";
import './Footer.scss'
import {SmallText} from "@odm/ui";
import {version} from "../core/constants/const";

const Footer = ({imprint, privacyPolicy}) => {
    return (
        <footer>
          <div className={"legal"}>
            <a href={imprint} target="_blank" rel="noreferrer">
              {i18n.t("ride provider imprint")}
            </a>
            <a href={privacyPolicy} target="_blank" rel="noreferrer">
              {i18n.t("ride provider privacy policy")}
            </a>
          </div>
          <SmallText element={"span"}>{i18n.t("version number", {version})}</SmallText>
        </footer>
    )
}



export default Footer