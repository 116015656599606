import {Enum} from "./Enum";

export class WeekDay extends Enum {
  static MONDAY = Symbol("MONDAY");
  static TUESDAY = Symbol("TUESDAY");
  static WEDNESDAY = Symbol("WEDNESDAY");
  static THURSDAY = Symbol("THURSDAY");
  static FRIDAY = Symbol("FRIDAY");
  static SATURDAY = Symbol("SATURDAY");
  static SUNDAY = Symbol("SUNDAY");


  constructor (state){
    super(state)
  }
}