import { Slide } from 'react-toastify';

export const animationTimingDefault = 200;

export const defaultToastOptions = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnHover: false,
  theme: "light",
  transition: Slide,
}

export const maxImageUploadSizeBytes = 5242880 // 5mb