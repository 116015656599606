import {format, formatRelative, getHours, getMinutes, parse} from "date-fns";
import {de} from 'date-fns/esm/locale';
import {isDate} from "lodash";
import {isString} from "formik";
import i18n from "../constants/i18n";

const locales = {
    de
}

export class DateTime {

    static getLocale(){return locales.de}

    constructor(date, options = {}) {
        const {formatString} = options;
        if(date instanceof DateTime) return date

        if(isDate(date)) this.value = date;
        if(isString(date)) this.value = parse(date,formatString,new Date(0));

        Object.defineProperty(this,'options',{value: options,enumerable: false,writable: false})
    }

    get hours () {return getHours(this.value)}
    get minutes () {return getMinutes(this.value)}

    get localizedTime () {
        return [format(this.value,'p',{locale: DateTime.getLocale()}),i18n.t("timeSuffix")].filter(Boolean).join(" ");
    }

    get localizedDate () {
        return format(this.value, 'P',{locale: DateTime.getLocale()})
    }

    formatRelative() {
        return formatRelative(this.value,new Date(),{locale: DateTime.getLocale()})
    }

    format(formatString) {
        return format(this.value,formatString);
    }

    formatRelativeDay() {
        const now = new Date();
        const isPast = this.value < now;
    
        const relativeDayLocale = {
            lastWeek: `'${i18n.t("last")} ${format(this.value, 'eeee', { locale: DateTime.getLocale() })}`,
            yesterday: `'${i18n.t("yesterday")}'`,
            today: `'${i18n.t("today")}'`,
            tomorrow: `'${i18n.t("tomorrow")}'`,
            nextWeek: "eeee",
            other: isPast ? `'${i18n.t("in the past")}'` : "eeee"
        };
    
        const locale = {
            ...DateTime.getLocale(),
            formatRelative: token => relativeDayLocale[token]
        };
    
        return formatRelative(this.value, now, { locale });
    }
    
    

    toDate() {
        return this.value
    }

    toString() {
        return format(this.value, this.options.formatString);
    }
    toJSON() {
        return format(this.value, this.options.formatString);
    }
    valueOf() {
        return this;
    }
}