import React from "react";
import "./RideOrderTypeFormSection.scss";
import { ICON_SIZES, IconButton, FormikSelect, ControlDecorator } from "@odm/ui";
import CmRideTypeIcon from "../components/CmRideTypeIcon";
import { Field, useFormikContext, getIn } from "formik";

const RideOrderTypeFormSection = ({ rideTypes, active }) => {
    const { setFieldValue, isSubmitting, errors } = useFormikContext();
    const maxVisibleButtonCount = 6
  
  
    const options = rideTypes.map((rideType) => ({
        value: rideType,
        label: rideType.description
    }));
    
    const activeRideType = active ? options.find(({ value }) => value.id === active.id) : null;

  return (
    <div className="ride-order-type-section">
        {rideTypes?.length > maxVisibleButtonCount ? (
            <ControlDecorator
                icon={<CmRideTypeIcon value={activeRideType.value} />}
                error={getIn(errors, "rideType")}
            >
                <Field
                    name="rideType"
                    as={FormikSelect}
                    id="select_rideTypes"
                    options={options}
                    defaultValue={activeRideType || undefined}
                />
            </ControlDecorator>
        ) : (
            <div className="button-section">
            {rideTypes.map((rideType) => (
                <IconButton
                key={rideType.id}
                id={`icon-button_ride-type-${rideType.description}`}
                icon={<CmRideTypeIcon value={rideType} />}
                label={rideType.description}
                title={rideType.description}
                onClick={() => setFieldValue("rideType", rideType)}
                inline={false}
                disabled={isSubmitting}
                size={ICON_SIZES.lg}
                active={active && active.id === rideType.id}
                />
            ))}
            </div>
        )}
    </div>
    );
};

export default RideOrderTypeFormSection;
