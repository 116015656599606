import React, {useEffect, useMemo, useState} from "react";
import {
  Button,
  BUTTON_EMPHASIS,
  ButtonGroup,
  ContentContainer, ControlDecorator,
  EmptyState,
  Input,
  PageHeader,
  PageSection,
  SectionError,
  Table
} from "@odm/ui";
import i18n from "../core/constants/i18n";
import {useSelector} from "react-redux";
import {getRideProviders, selectRideProvidersUndissolved} from "../core/redux/slices/rideProviderSlice";
import {generatePath, useNavigate} from "react-router-dom";
import {adminRideProvider, routeParameter} from "../core/constants/routes";
import RideProviderAddDialog from "../components/RideProviderAddDialog";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import ScrollableContent from "../components/ScrollableContent";
import {CiViewTable} from "react-icons/ci";
import {BsSearch} from "react-icons/bs";
import {MdSearch} from "react-icons/md";
import "./RideProvidersPage.scss";

const RideProvidersPage = () => {
  const rideProviders = useSelector(selectRideProvidersUndissolved);
  const [dispatchGetRideProviders, error] = useDispatchAsync(getRideProviders);
  const navigate = useNavigate();

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  useEffect(() => {
    dispatchGetRideProviders()
  }, [])

  const navigateToRideProviderPage = (id) => {
    const path = generatePath(adminRideProvider, {[routeParameter.rideProviderId]: id});
    navigate(path)
  }

  const handleCloseAddDialog = () => {
    setAddDialogOpen(false);
  }

  const columnKeys = {
    licenseNumber: "licenseNumber",
    companyName: "companyName",
    placeOfBusiness: "placeOfBusiness",
    actions: "actions"
  }

  const data = useMemo(() => rideProviders
      .filter(rideProvider => {
        return [
          rideProvider.licenseNumber,
          rideProvider.companyName,
          rideProvider.placeOfBusiness.city,
          rideProvider.placeOfBusiness.zip,
          rideProvider.placeOfBusiness.street,
        ]
        .join().toLowerCase()
        .includes(searchString.toLowerCase())
      })
      .map(rideProvider => ({
            id: rideProvider.id,
            [columnKeys.licenseNumber]: rideProvider.licenseNumber,
            [columnKeys.companyName]: rideProvider.companyName,
            [columnKeys.placeOfBusiness]: rideProvider.placeOfBusiness.inlineAddress
          })
      ), [rideProviders, searchString]
  )

  const columns = useMemo(() => ([
    {
      Header: i18n.t("company name"),
      accessor: columnKeys.companyName
    },
    {
      Header: i18n.t("license number"),
      accessor: columnKeys.licenseNumber
    },
    {
      Header: i18n.t("address"),
      accessor: columnKeys.placeOfBusiness
    }
  ]), []);

  const buildTable = () => {
    if (data.length > 0) {
      return (
          <Table columns={columns}
                 data={data}
                 getRowId={data => data.id}
                 onClick={(row) => {
                   navigateToRideProviderPage(row.original.id)
                 }}
          />
      )
    } else if (error) {
      return null
    } else if (rideProviders.length === 0) {
      return (
          <EmptyState title={i18n.t("no rideProviders")}
                      description={i18n.t("no rideProviders description")}
                      icon={<CiViewTable/>}
                      actions={
                        <Button title={i18n.t("add rideProvider")}
                                id="button_add-ride-provider"
                                emphasis={BUTTON_EMPHASIS.low}
                                onClick={() => setAddDialogOpen(true)}
                        />
                      }
          />
      )
    } else if (data.length === 0) {
      return (
          <EmptyState title={i18n.t("no rideProviders filter match")}
                      description={i18n.t("no rideProviders filter match description")}
                      icon={<BsSearch/>}
                      actions={
                        <>
                          <Button title={i18n.t("reset filter")}
                                  id="button_reset-filter"
                                  emphasis={BUTTON_EMPHASIS.low}
                                  onClick={() => setSearchString('')}
                          />
                        </>
                      }
          />
      )

    }
    return null;
  }

  return <>
    <PageHeader title={i18n.t("rideProviders")}
                actions={<ButtonGroup>
                  <Button title={i18n.t("add rideProvider")}
                          id="button_add-ride-provider"                       
                          onClick={() => setAddDialogOpen(true)}
                  />
                </ButtonGroup>}
    />
    <ScrollableContent>
      <ContentContainer>
        <div className={"search-container"}>
          <ControlDecorator icon={<MdSearch/>}>
            <Input placeholder={i18n.t("filter options", {possibilities: `${i18n.t("license number")}, ${i18n.t("company name")}, ${i18n.t("address")}`})}
                   id="input_search"
                   value={searchString}
                   onChange={(e) => setSearchString(e.target.value)}
            />
          </ControlDecorator>
        </div>

        <PageSection title={i18n.t("overview")}>
          <SectionError errorMessage={!!error && i18n.t("getRideProviders rejected")}/>
          {buildTable()}
        </PageSection>
      </ContentContainer>
    </ScrollableContent>

    <RideProviderAddDialog isOpen={addDialogOpen}
                           onClose={handleCloseAddDialog}
    />
  </>
}

export default RideProvidersPage;