import {Entity} from "./Entity";
import {DateTime} from "./DateTime";
import EntityArray from "./EntityArray";
import {WeekDay} from "./WeekDay";
import moment from "moment";

class BusinessHoursInformation extends Entity {
    static selectId = () => null
    static schema = {
        from:   [DateTime, {formatString: "HH:mm"}],
        till:   [DateTime, {formatString: "HH:mm"}],
        days:   [EntityArray,{type: [WeekDay]}]
    }

    static formValues (){
        return {
            from: "00:00",
            till: "00:00",
            days: []
        }
    }

    static formValuesAlways () {
        return {
            from: "00:00",
            till: "23:59",
            days: [
              new WeekDay(WeekDay.MONDAY.description),
              new WeekDay(WeekDay.TUESDAY.description),
              new WeekDay(WeekDay.WEDNESDAY.description),
              new WeekDay(WeekDay.THURSDAY.description),
              new WeekDay(WeekDay.FRIDAY.description),
              new WeekDay(WeekDay.SATURDAY.description),
              new WeekDay(WeekDay.SUNDAY.description)
            ]
        }
    }

    static mapIsoWeekDay(isoWeekDay){
      switch (isoWeekDay){
        case 1: return WeekDay.MONDAY;
        case 2: return WeekDay.TUESDAY;
        case 3: return WeekDay.WEDNESDAY;
        case 4: return WeekDay.THURSDAY;
        case 5: return WeekDay.FRIDAY;
        case 6: return WeekDay.SATURDAY;
        case 7: return WeekDay.SUNDAY;
      }
    }

    isValidForWeekDay(weekDay){
      return this.days.some((day)=>  day.value === weekDay)
    }

    isValidForTime(date){
      const time = moment({
        hour:date.hour(),
        minute:date.minute(),
        second:date.second()
      })

      return time.isBetween(moment(this.from.toString(),'HH:mm'), moment(this.till.toString(),'HH:mm'),undefined,"[)");
    }
    isValidForDate(datetime){
      const date = moment(datetime);
      const weekDay = BusinessHoursInformation.mapIsoWeekDay(date.isoWeekday())
      return this.isValidForWeekDay(weekDay) && this.isValidForTime(date)
    }

    constructor(data = {}) {
        super(data);
    }
}

export default Entity.applySchema(BusinessHoursInformation)