import CmRideType from "../../models/CmRideType";
import {EntitySliceHelper} from "../../util/EntitySliceHelper";
import rideProviderController from "../../http/controller/riderProviderController";


const helper = new EntitySliceHelper("rideType",CmRideType);


export const {  selectAll: selectRideTypes }= helper.getSelectors();

export const getRideTypes = helper.createAsyncAction(rideProviderController.getRideTypes);

const rideTypesSlice = helper.createSlice(
    {
        extraReducers: builder => {
            builder.addCase(getRideTypes.fulfilled, (state,action)=>{
                helper.adapter.setAll(state,action.payload)
            })
        }
    }
)

export default rideTypesSlice;