import {Entity} from "./Entity";
import {UserState} from "./UserState";
import {UserRole} from "./UserRole";

class User extends Entity {

    static selectId = (user) => user.userId;
    static schema = {
        rideProviderId:         [String, { writable: false }],
        rideOrdererId:          [String, { writable: false }],
        userId:                 [String, { writable: false }],
        userState:              [UserState, {writable: false}],
        role:                   [UserRole],
        firstName:              [String],
        lastName:               [String],
        phone:                  [String],
        password:               [String],
        email:                  [String],
        hasPushSubscription:    [Boolean],
        emailNotificationsEnabled: [Boolean],
        hasLoggedIn:            [Boolean]
    };

    static fromDefaultValues({rideProviderId,rideOrdererId,role}){
        return new User({
            rideProviderId,
            rideOrdererId,
            role,
            emailNotificationsEnabled: false,
            firstName:"",
            lastName: "",
            phone:    "",
            email:    "",
        })
    }

    constructor(data={}) {
        super(data);
    }
}

export default Entity.applySchema(User);