import {configureStore} from "@reduxjs/toolkit";
import rideOrdersSlice from "./slices/rideOrdersSlice";
import rideTypesSlice from "./slices/rideTypeSlice";
import localUserSlice from "./slices/localUserSlice";
import rideProviderSlice from "./slices/rideProviderSlice";
import userSlice from "./slices/userSlice";
import rideOrdererSlice from "./slices/rideOrdererSlice";
import { errorMiddleware } from "../util/ErrorMiddleware";
import globalErrorSlice from "./slices/globalErrorSlice";


const store = configureStore({
    reducer: {
        [rideOrdersSlice.name]:     rideOrdersSlice.reducer,
        [rideTypesSlice.name]:      rideTypesSlice.reducer,
        [localUserSlice.name]:      localUserSlice.reducer,
        [rideProviderSlice.name]:   rideProviderSlice.reducer,
        [userSlice.name]:           userSlice.reducer,
        [rideOrdererSlice.name]:    rideOrdererSlice.reducer,
        [globalErrorSlice.name]:    globalErrorSlice.reducer
    },
    middleware:  (getDefaultMiddleware)=> getDefaultMiddleware().concat(errorMiddleware)
})

export default store;