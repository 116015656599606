import {MdOutlineLocationOn, MdOutlineTripOrigin} from "react-icons/md";
import PlaceInput from "../components/PlaceInput";
import i18n from "../core/constants/i18n";
import {useFormikContext} from "formik";

const fieldNames = {
    startLocation:"startLocation",
    endLocation:"endLocation",

}

const RideOrderRouteFormSection = ({placeOfBusiness, additionalFormBlocks}) => {
    const {
        isSubmitting,
    } = useFormikContext();

    return (
        <>
                <PlaceInput placeholder={i18n.t("tripOrigin")}
                            additionalFormBlocks={additionalFormBlocks}
                            idSuffix="trip-origin"
                            showAdditionFormBlocks={true}
                            disabled={isSubmitting}
                            quickSelectValue={placeOfBusiness}
                            icon={<MdOutlineTripOrigin/>}
                            prefix={fieldNames.startLocation}
                />
                <PlaceInput placeholder={i18n.t("tripDestination")}
                            additionalFormBlocks={additionalFormBlocks}
                            idSuffix="trip-destination"
                            disabled={isSubmitting}
                            quickSelectValue={placeOfBusiness}
                            icon={<MdOutlineLocationOn/>}
                            prefix={fieldNames.endLocation}
                />
        </>
    )
}

export default RideOrderRouteFormSection;