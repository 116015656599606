import {Enum} from "./Enum";
import i18n from "../constants/i18n";

export class RideOrderState extends Enum {
    static CANCELED = Symbol("CANCELED");
    static NEW = Symbol("NEW");
    static CHANGED = Symbol("CHANGED")
    static COMMITTED = Symbol("COMMITTED")
    static REJECTED = Symbol("REJECTED")
    static ACCEPTED = Symbol("ACCEPTED")

    // FMS
    static EN_ROUTE = Symbol("EN_ROUTE")
    static ARRIVED_AT_PICKUP_LOCATION = Symbol("ARRIVED_AT_PICKUP_LOCATION")
    static PICKUP_COMPLETE = Symbol("PICKUP_COMPLETE")
    static COMPLETED = Symbol("COMPLETED")

    get uiString(){
        return i18n.t(`RideOrderState ${this.value.description}`)
    }

    constructor (state){
        super(state)
    }
}