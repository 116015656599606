export const routeParameter = {
  rideProviderId: "rideProviderId",
  rideOrdererId: "rideOrdererId",
}
export const baseUrlAdminRideProvider = '/admin/ride-provider'

const routes = {
  home: "/",
  rideOrders: "/ride-orders",

  adminRideProviders: "/admin/ride-providers",
  adminRideProvider: `${baseUrlAdminRideProvider}/:${routeParameter.rideProviderId}`
}

export const {
  home,
  rideOrders,
  adminRideProvider,
  adminRideProviders,
} = routes;