import {BodyText, Button, BUTTON_EMPHASIS, BUTTON_VARIANTS, InlineError, Modal} from "@odm/ui";
import i18n from "../core/constants/i18n";
import {dissolve} from "../core/redux/slices/userSlice";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import {toast} from "react-toastify";

const UserDissolveModal = ({user, isOpen, onClose}) => {

    const [dispatchDissolve,error] = useDispatchAsync(dissolve);

    const handleDissolveUser = () => {
        return dispatchDissolve({
            userId: user.id,
            rideProviderId: user.rideProviderId
        }).then(()=>{
          toast.success(i18n.t("remove user successful"));
          onClose();
        })
    }

    return (
        <Modal isOpen={isOpen}
               onClose={onClose}
               title={i18n.t("dissolve user")}
               actionsSlot={
                <>
                   <Button title={i18n.t("cancel")}
                           id="button_cancel"
                           variant={BUTTON_VARIANTS.cancel}
                           emphasis={BUTTON_EMPHASIS.low}
                           onClick={onClose}
                   />
                   <Button title={i18n.t("dissolve user")}
                           id="button_dissolve-user"
                           variant={BUTTON_VARIANTS.delete}
                           emphasis={BUTTON_EMPHASIS.high}
                           onClick={handleDissolveUser}
                   />
                </>
               }

        >
            <BodyText>{i18n.t("confirm dissolve user",{user: `${user?.email}`})}</BodyText>
            <InlineError errorMessage={error && i18n.t("user dissolve rejected")} />
        </Modal>
    )
}
export default UserDissolveModal;