import {
  BodyText,
  Button,
  BUTTON_VARIANTS,
  Checkbox,
  ControlDecorator,
  Input,
  PageSection,
  TransitionContainer
} from "@odm/ui";
import { Field, Formik, getIn } from "formik";
import i18n from "../core/constants/i18n";
import PlaceFormSection from "./PlaceFormSection";
import { VALIDATION_SCHEMATA } from "../core/constants/validationSchemata";
import { BlackPortal } from "react-native-portal";
import portalNames from "../core/constants/portalNames";
import FormErrorIcon from "../components/FormErrorIcon";
import React, { useEffect, useState } from "react";
import NarrowContainer from "../components/NarrowContainer";
import IPFormSection from "./IPFormSection";
import CustomScheduledTripsFormSection from "./CustomScheduledTripsFormSection";
import OrderCustomizationFormSection from "./OrderCustomizationFormSection";
import { isNil } from "lodash";
import { FORM_BLOCKS } from "../core/models/PageInformation";

const fieldNames = {
  name: "name",
  placeOfBusiness: "placeOfBusiness",
  ipWhitelist: {
    addressList: []
  }
}

const RideOrdererForm = ({ rideOrderer, onSubmit }) => {

  const [usesCustomForm, setUsesCustomForm] = useState(false);
  const [usesIPList, setUsesIPList] = useState(false);
  const [usesCustomScheduledTrips, setUsesCustomScheduledTrips] = useState(false);

  useEffect(() => {
    if (rideOrderer) {
      setUsesCustomForm(!isNil(rideOrderer?.additionalFormBlocks));
      setUsesIPList(!!rideOrderer?.ipWhitelist?.addressList?.length);
      setUsesCustomScheduledTrips(!!rideOrderer?.pickupTimeTypeAllowed || rideOrderer?.arrivalTimeTypeAllowed);
    }
  }, [rideOrderer]);

  return (
    <>
      <Formik initialValues={rideOrderer?.formValues()}
        onSubmit={(values) => {
          if (!usesCustomScheduledTrips) {
            values.pickupTimeTypeAllowed = null;
            values.arrivalTimeTypeAllowed = null;
          }
          if (!usesCustomForm) {
            values.additionalFormBlocks = null;
          } else {
            values.additionalFormBlocks = {
              ...Object.values(FORM_BLOCKS).reduce((map, value) => { map[value] = false; return map; }, {}),
              ...values.additionalFormBlocks,
            };
          }

          onSubmit(values);
        }}
        validateOnChange={true}
        enableReinitialize={true}
        validationSchema={VALIDATION_SCHEMATA.rideOrderer}
      >
        {({
          handleSubmit,
          touched,
          errors,
          dirty,
          isValid,
          isSubmitting,
          setFieldValue,
          submitForm
        }) => (
          <form onSubmit={handleSubmit}>
            <PageSection title={i18n.t("general")}>

              <ControlDecorator label={i18n.t("orderer name")}
                error={getIn(touched, fieldNames.name) && getIn(errors, fieldNames.name)}
              >
                <Field
                  as={Input}
                  id="input_orderer-name"
                  name={fieldNames.name}
                />
              </ControlDecorator>
              <PlaceFormSection prefix={fieldNames.placeOfBusiness} />
            </PageSection>


            <PageSection id={'ip-list-form'} title={i18n.t("ip-list form")}>
              <NarrowContainer>
              <BodyText>{i18n.t("ip-list form description", { orderer: rideOrderer?.name })}</BodyText>

                <PageSection>
                  <Checkbox
                    label={i18n.t("use ip-list", { orderer: rideOrderer?.name })}
                    id="checkbox_use-ip-list"
                    value={usesIPList}
                    onChange={(event) => {
                      const isChecked = event.target.checked;
                      const tmpAddressList = isChecked && rideOrderer?.ipWhitelist?.addressList ? [...rideOrderer.ipWhitelist.addressList] : [];
                      setUsesIPList(isChecked);
                      setFieldValue("ipWhitelist", {
                        addressList: tmpAddressList,
                      });
                    }}
                    checked={usesIPList}
                  />
                </PageSection>
                <PageSection>
                  <TransitionContainer show={usesIPList} transition={"height"}>
                    <IPFormSection />
                  </TransitionContainer>
                </PageSection>
              </NarrowContainer>
            </PageSection>


            <PageSection id={'custom-scheduled-trips-form'} title={i18n.t("custom scheduled trips form", { orderer: rideOrderer?.name })}>
              <NarrowContainer>
              <BodyText>{i18n.t("custom scheduled trips form description", { orderer: rideOrderer?.name })}</BodyText>

                <PageSection>
                  <Checkbox
                    label={i18n.t("custom scheduled trips", { orderer: rideOrderer?.name })}
                    id="checkbox_custom-scheduled-trips"
                    value={usesCustomScheduledTrips}
                    onChange={(event) => {
                      const { checked } = event.target;
                      setUsesCustomScheduledTrips(checked);
                      setFieldValue("pickupTimeTypeAllowed", checked);
                      setFieldValue("arrivalTimeTypeAllowed", checked);
                    }}
                    checked={usesCustomScheduledTrips}
                  />
                </PageSection>
                <PageSection>
                  <TransitionContainer show={usesCustomScheduledTrips} transition={"height"}>
                    <CustomScheduledTripsFormSection />
                  </TransitionContainer>
                </PageSection>
              </NarrowContainer>
            </PageSection>



            <PageSection id={'customize-order-form'} title={i18n.t("order form")}>
              <NarrowContainer>
                <BodyText>{i18n.t("customize local order form description", { orderer: rideOrderer?.name })}</BodyText>
                <PageSection>
                  <Checkbox label={i18n.t("use custom order form for orderer", { orderer: rideOrderer?.name })}
                    id="checkbox_use-custom-order-form"
                    value={usesCustomForm}
                    onChange={(event) => {
                      setUsesCustomForm(event.target.checked);
                      setFieldValue("additionalFormBlocks", null);
                    }}
                    checked={usesCustomForm}
                  />
                </PageSection>
                <PageSection>
                  <TransitionContainer show={usesCustomForm} transition={"height"}>
                    <OrderCustomizationFormSection />
                  </TransitionContainer>
                </PageSection>
              </NarrowContainer>
            </PageSection>

            <BlackPortal name={portalNames.headerBarAction}>
              <FormErrorIcon dirty={dirty} isValid={isValid} />
              <Button variant={BUTTON_VARIANTS.save}
                title={i18n.t("save")}
                id="button_save"
                loading={isSubmitting}
                disabled={!dirty}
                onClick={submitForm}
              />
            </BlackPortal>
          </form>
        )}
      </Formik>
    </>

  )
}

export default RideOrdererForm
