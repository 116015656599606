import { createSlice } from "@reduxjs/toolkit";

const sliceName = "globalError";

export const globalErrorSelector = (state) => state[sliceName];

const globalErrorSlice = createSlice({
  name: sliceName,
  initialState: {},
  reducers: {
    set: (state, action) => {
      return action.payload;
    }
  }
});

export default globalErrorSlice;
