import { Formik } from "formik";
import { Button, BUTTON_EMPHASIS, BUTTON_VARIANTS, CardActions, InlineError, Modal } from "@odm/ui";
import i18n from "../core/constants/i18n";
import { editOrder } from "../core/redux/slices/rideOrdersSlice";
import RideOrderDialogFormSection from "../forms/RideOrderDialogFormSection";
import { VALIDATION_SCHEMATA } from "../core/constants/validationSchemata";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { localUserSelector } from "../core/redux/slices/localUserSlice";
import { selectRideOrdererById } from "../core/redux/slices/rideOrdererSlice";
import { selectRideProviderById } from "../core/redux/slices/rideProviderSlice";
import RideOrder from "../core/models/RideOrder";

const RideOrderEditDialog = ({ rideOrder, referencedRideOrder, isOpen, onClose, isLoading }) => {
    
    const { endLocationArrivalTime, timeOfRide } = { ...referencedRideOrder };
    const referenceTimeOfRide = endLocationArrivalTime ? endLocationArrivalTime : timeOfRide;

    const referenceRideDateTime = new Date(referencedRideOrder?.dateOfRide + " " + referenceTimeOfRide);
  
    const [dispatchEditOrder, error] = useDispatchAsync(editOrder, [isOpen]);
    const { rideOrdererId, rideProviderId } = useSelector(localUserSelector);
    const rideOrderer = useSelector((state) => selectRideOrdererById(state, rideOrdererId));
    const rideProvider = useSelector((state) => selectRideProviderById(state, rideProviderId));
    const additionalFormBlocks = rideOrderer?.additionalFormBlocks || rideProvider?.pageInformation?.additionalFormBlocks || {};
    const initialValues = rideOrder?.formValues();

    if (initialValues) {
    initialValues.isArrivalTime = RideOrder.calculateTimingValues(rideProvider, rideOrderer, initialValues.isArrivalTime);
    };

    const handleUpdateOrder = async (values) => {
        try {
            await dispatchEditOrder(values);
            toast.success(i18n.t("edit successful"));
            onClose();
        } catch (error) {
            console.error(error);
            toast.error(i18n.t("edit failed"));
        }
    };

    return (
        <Modal title={i18n.t("transportEdit")} isOpen={isOpen} onClose={onClose}>
          {!isLoading && (
            <div className={"ride-order-edit-dialog"}>
                <Formik
                    initialValues={initialValues}
                    validateOnMount={true}
                    validateOnChange={true}
                    validationSchema={VALIDATION_SCHEMATA.dynamicRideOrder({
                        additionalFormBlocks,
                        rideProvider,
                        rideOrder,
                        referenceRideDateTime
                    })}
                    onSubmit={handleUpdateOrder}
                >
                    {({
                        isSubmitting,
                        handleSubmit,
                        isValid,
                        dirty
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <RideOrderDialogFormSection
                                rideOrder={rideOrder}
                                referenceRideDateTime={referenceRideDateTime}/>
                            <InlineError errorMessage={error && i18n.t("editOrder rejected")}/>
                            <CardActions>
                                <Button
                                    title={i18n.t("cancel")}
                                    id="button_cancel"
                                    emphasis={BUTTON_EMPHASIS.low}
                                    variant={BUTTON_VARIANTS.cancel}
                                    onClick={onClose}
                                    disabled={isSubmitting}
                                />
                                <Button
                                    title={i18n.t("save changes")}
                                    id="button_save"
                                    type={"submit"}
                                    disabled={!isValid || !dirty}
                                    loading={isSubmitting}
                                />
                            </CardActions>
                        </form>
                    )}
                </Formik>
            </div>
          )}
        </Modal>
    );
};

export default RideOrderEditDialog;
