import {Entity} from "./Entity";
import Place from "./Place";
import IPAddressList from "./IPAddressList";
import {RideOrdererState} from "./RideOrdererState";

class RideOrderer extends Entity {

    static selectId = (rideOrderer) => rideOrderer.rideOrdererId;

    static schema = {
        rideProviderId:         [String,{writable: false}],
        rideOrdererId:          [String,{writable: false}],
        name:                   [String],
        placeOfBusiness:        [Place, {nullable: false}],
        ipWhitelist:            [IPAddressList, {nullable: false}],
        rideOrdererState:       [RideOrdererState,{writable: false}],
        additionalFormBlocks:   [Object, {writable: true}],
        arrivalTimeTypeAllowed: [Boolean, {writable: true}],
        pickupTimeTypeAllowed:  [Boolean, {writable: true}],
    };

    static fromDefaultValues({rideProviderId}){
        return new RideOrderer({
            name:"",
            rideProviderId
        })
    }

    constructor(data={}) {
        super(data);
    }
}

export default Entity.applySchema(RideOrderer);
