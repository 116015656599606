import React from "react";
import "./CautionWrapper.scss";
import {FlexRow, HEADLINE_TEXT_SIZES, HeadlineText, IconContainer} from "@odm/ui";
import {IoWarning} from "react-icons/io5";
import {AiOutlineInfoCircle} from "react-icons/ai";
import {Tooltip as ReactTooltip} from "react-tooltip";

const CautionWrapper = ({title, message, children, actions}) => {

  const iconColor = "#f7b731";

  return <div className={"caution-wrapper"}>
    <FlexRow align={"center"}>
      <IconContainer icon={<IoWarning color={iconColor}/>}/>
      <HeadlineText size={HEADLINE_TEXT_SIZES.md} element={"h3"}>
        {title}
      </HeadlineText>
      {message ?
          <>
            <ReactTooltip
                id={"info-icon"}
                place={"top"}
            />
            <div
                className={"tooltip-anchor"}
                data-tooltip-id={"info-icon"}
                data-tooltip-content={message}>
              <IconContainer icon={<AiOutlineInfoCircle color={iconColor}/>
              }/>
            </div>

          </>
          : null
      }
      {actions ? <div className={"actions"}>{actions}</div> : null}
    </FlexRow>

    {children}
  </div>

}

export default CautionWrapper;