import {HttpController, RESPONSE_STRUCTURES} from "../../util/HttpController";
import {resendVerificationMail} from "../../redux/slices/userSlice";

const controller = new HttpController('/api/user/');

const userController = {
    getUsers: (payload, config) => controller.post(
        'getUsers',
        payload,
        {responseStructure: RESPONSE_STRUCTURES.page,...config}),
    getUser:  (payload, config) => controller.post('getUser',payload,config),
    edit:     (payload, config) => controller.post('edit',payload,config),
    dissolve: (payload, config) => controller.post('dissolve',payload,config),
    add:   (payload, config) => controller.post('add',payload,config),
    resendVerificationMail: (payload,config) => controller.post("resendVerificationMail",payload,config)
}

export default userController;