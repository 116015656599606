import React from "react";
import {ControlDecorator, FormikSelect} from "@odm/ui";
import {Field, getIn, useFormikContext} from "formik";
import {PiWheelchair} from "react-icons/pi";
import i18n from "../core/constants/i18n";

const DegreeOfCareFormSection = () => {
  const {touched, errors, values} = useFormikContext();

  const options = [
    {value: null, label: i18n.t("degreeOfCare_none")},
    {value: 1, label: i18n.t("degreeOfCare_1")},
    {value: 2, label: i18n.t("degreeOfCare_2")},
    {value: 3, label: i18n.t("degreeOfCare_3")},
    {value: 4, label: i18n.t("degreeOfCare_4")},
    {value: 5, label: i18n.t("degreeOfCare_5")},
  ]

  const value = getIn(values, "degreeOfCare");
  let selectedOption;

  if (value) {
    selectedOption = options.find(opt => opt.value === value);
  }

  return <ControlDecorator icon={<PiWheelchair/>}
                           label={i18n.t("degreeOfCare")}
                           labelFor={"degreeOfCare"}
                           error={getIn(touched, "degreeOfCare") && getIn(errors, "degreeOfCare")}>

    <Field id="select_degree-of-care" as={FormikSelect} name={"degreeOfCare"} options={options} defaultValue={selectedOption || options[0]}/>
  </ControlDecorator>
}

export default DegreeOfCareFormSection;