import "./HeroHeader.scss";
import i18n from "../core/constants/i18n";
import {Card, DISPLAY_TEXT_SIZES, DisplayText, HeadlineText} from "@odm/ui"

const HeroHeader = ({title, subtitle, heroImage, content})=>{

    return <div className={"hero-header"}>

        <picture className={"hero-header_image"}>
            <img src={heroImage} />
        </picture>

        <header className={"hero-header_header"}>
            <DisplayText size={DISPLAY_TEXT_SIZES.md} element={"h1"}>{title}</DisplayText>
            <HeadlineText  element={"p"}>{subtitle}</HeadlineText>
        </header>

        <div className={"hero-header_content"}>
            <Card headerSlot={<h2 className={"order-heading"}>{i18n.t("orderTransport")}</h2>}>
                {content}
            </Card>
        </div>
    </div>
}

export default HeroHeader;