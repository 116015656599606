import React from "react";
import {Field, getIn, useFormikContext} from "formik";
import {Checkbox, ControlDecorator} from "@odm/ui";
import i18n from "../core/constants/i18n";
import {FaPeopleCarry} from "react-icons/fa";

const CarryingAidFormSection = () => {

  const {values} = useFormikContext();

  return <ControlDecorator icon={<FaPeopleCarry/>}>
    <Field as={Checkbox}
           id="checkbox_carrying-aid-required"
           name={"carryingAidRequired"}
           label={i18n.t("carryingAidRequiredLabel")}
           checked={getIn(values,"carryingAidRequired")}
    />
  </ControlDecorator>
}

export default CarryingAidFormSection;