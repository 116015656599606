import React from "react";
import ReactModal from "react-modal";
import {Provider} from "react-redux";
import store from "./core/redux/store";
import KeycloakAuthProvider from "./auth/KeycloakAuthProvider";
import "./App.scss";
import {BrowserRouter} from "react-router-dom";
import WebOrderContent from "./components/WebOrderContent";
import ErrorNavigator from "./components/ErrorNavigator";
import ThemeContextProvider from "./components/ThemeContextProvider";
import {MediaContextProvider} from "@odm/ui";
import {PortalProvider} from "react-native-portal";
import {ToastContainer} from "react-toastify";
import {defaultToastOptions} from "./core/constants/ui";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  ReactModal.setAppElement('#root');
  return (
      <Provider store={store}>
        <PortalProvider>
          <KeycloakAuthProvider>
            <ErrorNavigator>
            <BrowserRouter>
              <ThemeContextProvider>
                <MediaContextProvider>
                  <ToastContainer {...defaultToastOptions}/>
                  <WebOrderContent/>
                </MediaContextProvider>
              </ThemeContextProvider>
            </BrowserRouter>
            </ErrorNavigator>
          </KeycloakAuthProvider>
        </PortalProvider>
      </Provider>
  );
}

export default App;
