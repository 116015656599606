import {BodyText, Button, BUTTON_EMPHASIS, BUTTON_VARIANTS, InlineError, Modal} from "@odm/ui";
import i18n from "../core/constants/i18n";
import {dissolve} from "../core/redux/slices/rideOrdererSlice";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import {toast} from "react-toastify";

const RideOrdererDissolveModal = ({rideOrderer, isOpen, onClose}) => {

    const [dispatchDissolve,error] = useDispatchAsync(dissolve);

    const handleDissolveRideOrderer = () => {
        return dispatchDissolve({
            rideOrdererId: rideOrderer.id,
            rideProviderId: rideOrderer.rideProviderId
        }).then(()=>{
          toast.success(i18n.t("dissolve orderer successful"));
          onClose();
        })
    }

    return (
        <Modal isOpen={isOpen}
               onClose={onClose}
               title={i18n.t("dissolve rideOrderer")}
               actionsSlot={
                <>
                   <Button title={i18n.t("cancel")}
                           id="button_cancel"
                           variant={BUTTON_VARIANTS.cancel}
                           emphasis={BUTTON_EMPHASIS.low}
                           onClick={onClose}
                   />
                   <Button title={i18n.t("dissolve rideOrderer")}
                           id="button_dissolve-ride-orderer"
                           variant={BUTTON_VARIANTS.delete}
                           emphasis={BUTTON_EMPHASIS.high}
                           onClick={handleDissolveRideOrderer}
                   />
                </>
               }

        >
            <BodyText>{i18n.t("confirm dissolve rideOrderer",{rideOrderer: `${rideOrderer?.name}`})}</BodyText>
            <InlineError errorMessage={error && i18n.t("rideOrderer dissolve rejected")}/>
        </Modal>
    )
}
export default RideOrdererDissolveModal;