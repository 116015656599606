import { useEffect, useRef, useState } from "react";
import { defaultsDeep, isEmpty, isNil, head, omit } from "lodash";
import i18n from "../core/constants/i18n";
import {
  Button,
  BUTTON_EMPHASIS,
  ButtonGroup,
  CONTROL_APPEARANCE,
  CONTROL_DECORATOR_CONTROL_HEIGHTS,
  ControlDecorator,
  ControlPreviewWrapper,
  ControlSuggestions,
  ICON_SIZES,
  IconButton,
  IconContainer,
  Input,
} from "@odm/ui";
import "./PlaceInput.scss";
import Place from "../core/models/Place";
import classNames from "classnames";
import { MdClear, MdExpandLess, MdExpandMore, MdHome, MdPlace } from "react-icons/md";
import { Field, getIn, useFormikContext } from "formik";
import { FORM_BLOCKS } from "../core/models/PageInformation";

const PlaceInput = ({
  idSuffix,
  placeholder,
  disabled,
  quickSelectValue,
  icon,
  prefix,
  additionalFormBlocks,
  showAdditionFormBlocks
}) => {

  const fieldNames = {
    street: `${prefix}.address.street`,
    zip: `${prefix}.address.zip`,
    city: `${prefix}.address.city`,
    country: `${prefix}.address.country`,
    placeDescription: `${prefix}.placeDescription`
  }

  const autoCompleteService = useRef(new window.google.maps.places.AutocompleteService());
  const placeService = useRef(new window.google.maps.places.PlacesService(document.createElement('div')))
  const { values, setFieldValue, errors, touched, validateForm } = useFormikContext();
  const [placeSuggestions, setPlaceSuggestions] = useState([]);
  const [zipSuggestions, setZipSuggestions] = useState([]);
  const [streetSuggestions, setStreetSuggestions] = useState([]);
  const [citySuggestions, setCitySuggestions] = useState([]);
  const [preview, setPreview] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const isStreetTouched = getIn(touched, fieldNames.street);
  const isZipTouched = getIn(touched, fieldNames.zip);
  const isCityTouched = getIn(touched, fieldNames.city);
  const streetError = getIn(errors, fieldNames.street) && (isStreetTouched || isZipTouched || isCityTouched);
  const zipError = getIn(errors, fieldNames.zip) && (isZipTouched || isCityTouched);
  const cityError = getIn(errors, fieldNames.city) && isCityTouched;
  const additionalFormBlocksTouched = showAdditionFormBlocks && getIn(touched, "placeSpecifierComment")
  const isAnyAddressTouched = Object.values(omit(fieldNames,"placeDescription")).some((fieldname)=>getIn(touched,fieldname))

  const addressErrors = getIn(errors, `${prefix}.address`) ?? {};
  const lastTouchedFieldIndex = Object.keys(addressErrors).findLastIndex((key)=>getIn(touched,`${prefix}.address.${key}`))

  let error = isAnyAddressTouched ?
    head(Object.values(addressErrors).slice(0,lastTouchedFieldIndex + 1))
    : null
  const placeDescriptionTouched = getIn(touched, fieldNames.placeDescription)

  const placeSpecifierCommentError = showAdditionFormBlocks && getIn(errors, "placeSpecifierComment");
  error = placeSpecifierCommentError !== undefined ? placeSpecifierCommentError : error;

  useEffect(() => {
    if (error && placeDescriptionTouched) {
      setExpanded(true)
    }
    if (additionalFormBlocks[FORM_BLOCKS.placeSpecifierComment] && showAdditionFormBlocks) {
      setExpanded(true)
    }
  }, [error])
  

  const PlaceInputControls = ({ idSuffix }) => (
    <ButtonGroup className={"actions"}>
      <Button type={"button"}
        onClick={handleClear}
        id={`button_clear-${idSuffix}`}
        icon={<MdClear />}
        emphasis={BUTTON_EMPHASIS.minimal}
      />
      <Button type={"button"}
        onClick={() => {
          setExpanded(!expanded)
        }}
        id={`button_expand-place-${idSuffix}`}
        icon={expanded ? <MdExpandLess /> : <MdExpandMore />}
        title={i18n.t("details")}
        emphasis={BUTTON_EMPHASIS.minimal}
      />
      {quickSelectValue ? <IconButton type={"button"}
        onClick={() => {
          setFieldValue(prefix, new Place(quickSelectValue))
        }}
        id={`icon-button_quick-select-${idSuffix}`}
        icon={<MdHome />}
        size={ICON_SIZES.sm}
      /> : null}

    </ButtonGroup>
  )

  const buildSuggestionTitle = (prediction) => {
    const { main_text_matched_substrings, main_text } = prediction.structured_formatting;

    if (isEmpty(main_text_matched_substrings)) return main_text;

    const elements = [];

    elements.push(main_text.substring(0, main_text_matched_substrings[0].offset));

    main_text_matched_substrings.forEach(({ offset, length }, index, matches) => {

      elements.push(<mark>{main_text.substring(offset, offset + length)}</mark>);

      if (index + 1 !== matches.length) {
        elements.push(main_text.substring(offset + length, matches[index + 1].offset));
      } else {
        elements.push(main_text.substring(offset + length, main_text.length));
      }
    });
    return elements;
  };

  const updatePreview = async (placeId) => {
    if (!placeId) {
      setPreview(null);
      return;
    }

    const placeDetails = await getPlaceDetails(placeId);
    const place = Place.fromPlaceDetails(placeDetails);

    if (place) {
      setPreview(place);
    }
  };

  const applyPreview = async (placeId) => {
    const placeDetails = await getPlaceDetails(placeId);
    const place = Place.fromPlaceDetails(placeDetails);
    setFieldValue(prefix, defaultsDeep(place, getIn(values, prefix)));
    setPreview(null);
  };

  const buildSuggestions = (predictions) => {
    if (isNil(predictions)) return [];

    return predictions.map((prediction) => ({
      title: buildSuggestionTitle(prediction),
      description: prediction.structured_formatting.secondary_text,
      id: prediction.place_id,
      value: prediction.place_id,
      leadingSlot: <IconContainer icon={<MdPlace />} />,
      onSelect: applyPreview,
      onActive: updatePreview
    }))
  };
  const getPredictionsOfType = (input, types) => {

    const bounds = quickSelectValue.getBounds(25)

    return new Promise((resolve) => autoCompleteService.current.getPlacePredictions({
      input,
      types,
      componentRestrictions: {
        country: ["de"]
      },
      bounds
    }, (predictions) => resolve(predictions)
    ));
  };
  const getPlaceDetails = (placeId) => {
    const request = {
      placeId,
      fields: ["name", "place_id", "address_components", "geometry"],
    };

    return new Promise((resolve, reject) => {
      placeService.current.getDetails(request, (result, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && !isNil(result)) {
          resolve(result);
        } else {
          reject();
        }
      });
    });
  };

  const handleClear = () => {
    const place = new Place()
    setFieldValue(prefix, place)
  }
  const buildPreviewValue = (path) => {
    const value = getIn(preview, path);
    if (value) return { value };
    else return null;
  };
  const getPlacePredictions = async (e) => {
    const predictions = await getPredictionsOfType(e.target.value);
    const suggestions = buildSuggestions(predictions);
    setPlaceSuggestions(suggestions);
    validateForm();
  };

  const getZipPredictions = async (e) => {
    const predictions = await getPredictionsOfType(e.target.value, ["postal_code"]);
    const suggestions = buildSuggestions(predictions);
    setZipSuggestions(suggestions);
    validateForm();
  };

  const getStreetPredictions = async (e) => {
    const predictions = await getPredictionsOfType(e.target.value, ["address"]);
    const suggestions = buildSuggestions(predictions);
    setStreetSuggestions(suggestions);
    validateForm();
  };
  const getCityPredictions = async (e) => {
    const predictions = await getPredictionsOfType(e.target.value, ["(cities)"]);
    const suggestions = buildSuggestions(predictions);
    setCitySuggestions(suggestions);
    validateForm();
  };


  return (
    <div className={classNames("place-input-container", { "expanded": expanded })}>
      <ControlDecorator icon={icon}
        controlHeight={CONTROL_DECORATOR_CONTROL_HEIGHTS.auto}
      >

        <div className={"controls"}>
          <ControlSuggestions suggestions={placeSuggestions}
            onBlur={() => updatePreview(null)}
          >
            <ControlPreviewWrapper preview={buildPreviewValue("placeDescription")}>
              <Field name={fieldNames.placeDescription}
                as={Input}
                id={`input_${idSuffix}`}
                placeholder={placeholder}
                invalid={!expanded && placeDescriptionTouched && error}
                controlAppearance={CONTROL_APPEARANCE.filled}
                onKeyUp={getPlacePredictions}
              />
            </ControlPreviewWrapper>
          </ControlSuggestions>
          {expanded ? (
            <>
              {
                additionalFormBlocks[FORM_BLOCKS.placeSpecifierComment] && showAdditionFormBlocks
                  ? <Field
                    as={Input}
                    id={`input_place-specifier-comment-placeholder-${idSuffix}`}
                    name={"placeSpecifierComment"}
                    placeholder={i18n.t("placeSpecifierCommentPlaceholder")}
                    disabled={disabled}
                    controlAppearance={CONTROL_APPEARANCE.filled}
                    invalid={getIn(errors, "placeSpecifierComment") && (additionalFormBlocksTouched || isAnyAddressTouched)}
                  />
                  : null
              }
              <ControlSuggestions suggestions={streetSuggestions}
                onBlur={() => updatePreview(null)}
              >
                <ControlPreviewWrapper preview={buildPreviewValue("street")}>
                  <Field as={Input}
                    name={fieldNames.street}
                    id={`input_street-${idSuffix}`}
                    invalid={streetError}
                    onKeyUp={getStreetPredictions}
                    disabled={disabled}
                    placeholder={i18n.t("street")}
                    controlAppearance={CONTROL_APPEARANCE.filled}
                  />
                </ControlPreviewWrapper>
              </ControlSuggestions>
              <ControlSuggestions suggestions={zipSuggestions}
                onBlur={() => updatePreview(null)}
              >
                <ControlPreviewWrapper preview={buildPreviewValue("zip")}>
                  <Field as={Input}
                    name={fieldNames.zip}
                    id={`input_zip-${idSuffix}`}
                    invalid={zipError}
                    onKeyUp={getZipPredictions}
                    disabled={disabled}
                    placeholder={i18n.t("zip")}
                    controlAppearance={CONTROL_APPEARANCE.filled}
                  />
                </ControlPreviewWrapper>
              </ControlSuggestions>
              <ControlSuggestions suggestions={citySuggestions}
                onBlur={() => updatePreview(null)}
              >
                <ControlPreviewWrapper preview={buildPreviewValue("city")}>
                  <Field as={Input}
                    name={fieldNames.city}
                    id={`input_city-${idSuffix}`}
                    invalid={cityError}
                    onKeyUp={getCityPredictions}
                    disabled={disabled}
                    placeholder={i18n.t("city")}
                    controlAppearance={CONTROL_APPEARANCE.filled}
                  />
                </ControlPreviewWrapper>
              </ControlSuggestions>
            </>
          ) : null}
          <PlaceInputControls idSuffix={idSuffix} />
        </div>
        {error && (isAnyAddressTouched || additionalFormBlocksTouched) ? <small className={"error-message"}>{error}</small> : null}
      </ControlDecorator>
    </div>
  )
}

export default PlaceInput;
