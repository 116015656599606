import React from "react";
import "./FormErrorIcon.scss"
import i18n from "../core/constants/i18n";
import {BiErrorCircle} from "react-icons/bi";
import {IconContainer} from "@odm/ui";

const FormErrorIcon = (props) => {
  const {dirty, isValid} = props;

  if (dirty && !isValid) {
    return <div className={"form-error-icon"}>
      <IconContainer label={i18n.t("form contains errors")}
                     icon={<BiErrorCircle/>}/>
    </div>
  }

  return null;
}

export default FormErrorIcon;