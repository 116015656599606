import {Button, BUTTON_EMPHASIS, BUTTON_VARIANTS, ControlDecorator, InlineError, Input, Modal} from "@odm/ui";
import {Field, Formik, getIn} from "formik";
import RideProvider from "../core/models/RideProvider";
import i18n from "../core/constants/i18n";
import {generatePath, useNavigate} from "react-router-dom";
import {add} from "../core/redux/slices/rideProviderSlice";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import {adminRideProvider, routeParameter} from "../core/constants/routes";
import {VALIDATION_SCHEMATA} from "../core/constants/validationSchemata";
import {toast} from "react-toastify";
import React from "react";
import randomstring from "randomstring";

const fieldNames = {
    companyName: "companyName",
    licenseNumber: "licenseNumber",
    tenant: "tenant",
    department: "department",
    apiKey: "apiKey",
}

const RideProviderAddDialog = ({isOpen,onClose})=> {

    const [dispatchAdd,error] = useDispatchAsync(add);
    const navigate = useNavigate();

    const handleCreate = (values) => {
        return dispatchAdd(values).then((res)=>{
            const rideProvider = new RideProvider(res.payload)
            const path = generatePath(adminRideProvider,{[routeParameter.rideProviderId]: rideProvider.id});
            navigate(path);
            toast.success(i18n.t("create ride provider successful"));
        })
    }

    return (
        <Formik initialValues={{
            ...RideProvider.formValues(),
            [fieldNames.apiKey]: randomstring.generate(),
        }}
                validateOnChange={true}
                onSubmit={(values)=>handleCreate(values)}
                validationSchema={VALIDATION_SCHEMATA.rideProviderAdd}
                enableReinitialize={true}
        >
            {({ submitForm,
                isSubmitting,
                  errors,
                  touched,
                  dirty,
                  isValid,
                  resetForm
            }) => (
                <Modal isOpen={isOpen}
                       onClose={onClose}
                       title={i18n.t("add rideProvider")}
                       actionsSlot={<>
                           <Button title={i18n.t("cancel")}
                                   id="button_cancel"
                                   variant={BUTTON_VARIANTS.cancel}
                                   emphasis={BUTTON_EMPHASIS.low}
                                   onClick={()=> {
                                       resetForm()
                                       onClose()
                                   }}
                           />
                           <Button title={i18n.t("create")}
                                   id="button_create-ride-provider"
                                   variant={BUTTON_VARIANTS.add}
                                   emphasis={BUTTON_EMPHASIS.high}
                                   onClick={submitForm}
                                   disabled={!dirty || !isValid}
                           />
                       </>}
                >
                    <ControlDecorator label={i18n.t("company name")}
                                      error={getIn(touched,fieldNames.companyName) && getIn(errors,fieldNames.companyName)}
                    >
                        <Field as={Input} id="input_company-name" name={fieldNames.companyName} disabled={isSubmitting} />
                    </ControlDecorator>
                    <ControlDecorator label={i18n.t("license number")}
                                      error={getIn(touched, fieldNames.licenseNumber) && getIn(errors, fieldNames.licenseNumber)}>
                        <Field as={Input} id="input_license-number" name={fieldNames.licenseNumber}
                        />
                    </ControlDecorator>
                    <ControlDecorator label={i18n.t("tenant")}
                                      error={getIn(touched, fieldNames.tenant) && getIn(errors, fieldNames.tenant)}>
                        <Field as={Input} id="input_tenant" name={fieldNames.tenant}
                        />
                    </ControlDecorator>
                    <ControlDecorator label={i18n.t("department")}
                                      error={getIn(touched, fieldNames.department) && getIn(errors, fieldNames.department)}>
                        <Field as={Input} id="input_department" name={fieldNames.department}
                        />
                    </ControlDecorator>

                    <Field as={Input} id="input_api-key" type={"hidden"} name={fieldNames.apiKey}
                    />

                    <InlineError errorMessage={!!error && i18n.t("rideProviderAdd rejected")}/>
                </Modal>
            )}
        </Formik>
    )
}

export default RideProviderAddDialog;