import { ButtonToggle, Calendar, InlineError, HeadlineText } from "@odm/ui";
import i18n from "../core/constants/i18n";
import TimePicker from "../components/TimePicker";
import { useFormikContext } from "formik";
import { DateTime } from "../core/models/DateTime";
import RideOrder from "../core/models/RideOrder";
import moment from "moment/moment";
import "./RideOrderTimingFormSection.scss";

const RideOrderTimingFormSection = ({ rideProvider, rideOrderer, rideOrder }) => {
  const { values, setFieldValue, errors } = useFormikContext();

  const getMinDate = () => {
    if (!rideOrder?.isNew) return new Date();

    let minDate = moment();

    while (!rideProvider.isRideDateWithinOrderLimit(minDate)) {
      minDate.add(1, "day");
    }
    return minDate.toDate();
  };


  let pickupTimeTypeAllowed = rideProvider?.pickupTimeTypeAllowed;
  let arrivalTimeTypeAllowed = rideProvider?.arrivalTimeTypeAllowed;

  if (rideOrderer?.pickupTimeTypeAllowed || rideOrderer?.arrivalTimeTypeAllowed) {
    pickupTimeTypeAllowed = rideOrderer.pickupTimeTypeAllowed;
    arrivalTimeTypeAllowed = rideOrderer.arrivalTimeTypeAllowed;
  }

  return (
    <>
      {pickupTimeTypeAllowed && arrivalTimeTypeAllowed ? (
        <ButtonToggle
          options={[
            { label: i18n.t("pickup at"), value: false },
            { label: i18n.t("arrival at"), value: true },
          ]}
          id="button-toggle_pickup-arrival"
          onChange={(e) => setFieldValue("isArrivalTime", e)}
          activeOption={values.isArrivalTime}
        />
      ): (
      <HeadlineText className="ride-order-timing-headline" element="p">
          {values.isArrivalTime ? i18n.t("arrival at"): i18n.t("pickup at")}
      </HeadlineText>
      )}
      <TimePicker
        value={values.timeOfRide}
        onChange={(e) =>
          setFieldValue("timeOfRide", new DateTime(e, RideOrder.schema.timeOfRide[1]))
        }
      />
      <Calendar
        value={values.dateOfRide.toDate()}
        minDate={getMinDate()}
        onChange={(e) =>
          setFieldValue("dateOfRide", new DateTime(e, RideOrder.schema.dateOfRide[1]))
        }
      />
      <InlineError errorMessage={errors.timeOfRide} />
    </>
  );
};

export default RideOrderTimingFormSection;
