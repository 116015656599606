import {Entity} from "./Entity";

class ContactInformation extends Entity {
    static selectId = (contactInformation => null) //TODO: Can there be an Entity without id?
    static schema = {
        firstName: [String],
        lastName:  [String],
        phone:     [String],
        email:     [String]
    }

    constructor(data = {}) {
        super(data);
    }
}

export default Entity.applySchema(ContactInformation)