import React from "react";
import {Checkbox, ControlDecorator, DynamicGrid, DynamicRow, DynamicRowElement} from "@odm/ui";
import i18n from "../core/constants/i18n";
import {Field, getIn, useFormikContext} from "formik";
import {FORM_BLOCKS} from "../core/models/PageInformation";

const OrderCustomizationFormSection = ({}) => {
  const fieldNamePrefix = "additionalFormBlocks."
  const {values} = useFormikContext();

  return <fieldset>
    <DynamicGrid numCols={2}>
      <DynamicRow>
        {Object.keys(FORM_BLOCKS).map(key => {
          const fieldName = FORM_BLOCKS[key];
          return <DynamicRowElement colSpan={1}>
            <ControlDecorator>
              <Field as={Checkbox}
                     id={`checkbox_additional-form-blocks-${fieldName}`}
                     name={fieldNamePrefix + fieldName}
                     label={i18n.t(key)}
                     checked={getIn(values,fieldNamePrefix + fieldName)}
              />
            </ControlDecorator>
          </DynamicRowElement>
        })}
      </DynamicRow>
    </DynamicGrid>
  </fieldset>
}

export default OrderCustomizationFormSection;