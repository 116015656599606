import React from "react";
import {useSelector} from "react-redux";
import {localUserSelector} from "../core/redux/slices/localUserSlice";
import {Spinner} from "@odm/ui";
import NotAuthorizedPage from "../pages/NotAuthorizedPage";

const ProtectedContent = ({minimumRole, children}) => {
  const localUser = useSelector(localUserSelector);

  if (!localUser?.role) {
    return <Spinner/>
  }

  if (localUser.hasAtLeastRole(minimumRole)) {
    return children;
  } else {
    return <NotAuthorizedPage/>;
  }
}

export default ProtectedContent;