import {EntitySliceHelper} from "../../util/EntitySliceHelper";
import RideOrderer from "../../models/RideOrderer";
import rideOrdererController from "../../http/controller/rideOrdererController";
import {createSelector} from "@reduxjs/toolkit";
import {omit} from "lodash";
import {RideOrdererState} from "../../models/RideOrdererState";

const helper = new EntitySliceHelper("rideOrderer", RideOrderer);

export const {  selectAll: selectRideOrderers, selectById: selectRideOrdererById }= helper.getSelectors();

export const selectUndissolvedRideOrderers = createSelector(
    [selectRideOrderers],
    (rideOrderers)=> (
        rideOrderers.filter(rideOrderer => rideOrderer.rideOrdererState.value !== RideOrdererState.DISSOLVED)
    )
)

export const selectUndissolvedRideOrderersByRideProviderId = createSelector(
    [selectUndissolvedRideOrderers,(state,rideProviderId)=> rideProviderId],
    (rideOrderers,rideProviderId)=> {
        return rideOrderers.filter(rideOrderer => rideOrderer.rideProviderId === rideProviderId)
    })

export const getRideOrderers = helper.createAsyncAction(rideOrdererController.getRideOrderers);
export const getRideOrderer = helper.createAsyncAction(rideOrdererController.getRideOrderer);
export const edit = helper.createAsyncAction(rideOrdererController.edit);
export const dissolve = helper.createAsyncAction(rideOrdererController.dissolve);
export const define = helper.createAsyncAction(rideOrdererController.add);

// TODO: mostly boilerplate, check before usage
const rideOrdererSlice = helper.createSlice({
    extraReducers: builder => {
        builder.addCase(getRideOrderers.fulfilled,(state,action)=>{

            helper.adapter.setAll(state,action.payload.map(rideOrderer => omit(rideOrderer,'users')))
        })
        builder.addCase(getRideOrderer.fulfilled,(state,action)=>{
            helper.adapter.upsertOne(state,omit(action.payload,'users'))
        })
        builder.addCase(edit.fulfilled,(state,action)=>{
            helper.adapter.upsertOne(state,omit(action.payload,'users'))
        })
        builder.addCase(dissolve.fulfilled,(state,action)=>{
            helper.adapter.upsertOne(state,omit(action.payload,'users'))
        })
        builder.addCase(define.fulfilled,(state,action)=>{
            helper.adapter.upsertOne(state,omit(action.payload,'users'))
        })
    }
})

export default rideOrdererSlice;