import {isNil, mapValues} from "lodash";

export class Entity {

    static applySchema (target){
        const {schema, prototype} = target;
        const getFormValues = (v) => {
            if(isNil(v)) return v;
            if("formValues" in v) return v.formValues();
            return v.valueOf();
        }
        Object.entries(schema).forEach(([key,[fieldType,fieldOptions = {}]])=> {

            Object.defineProperty(prototype, key, {
                get: function () {
                    return this.values[key]?.valueOf()
                },
                set: function (value) {
                    if(fieldOptions.nullable && isNil(value)){
                        Reflect.set(this.values,key, null)
                    }
                    else if(value instanceof schema[key][0]){

                        Reflect.set(this.values,key, value)
                    }
                    else{
                        Reflect.set(this.values,key, new fieldType(value, fieldOptions))
                    }
                },
                enumerable: fieldOptions.enumerable ?? true
            })
        })

        Object.defineProperty(prototype,'id',{
            get: function() {return target.selectId(this.values).valueOf()},
            enumerable: false
        })

        Object.defineProperty(prototype,'payload',{
            value: function() { return this.values },
            enumerable: false
        })

        Object.defineProperty(prototype,'toJSON',{
            value: function() { return this.values },
            enumerable: false
        })

        Object.defineProperty(prototype,'formValues',{
            value: function() { return mapValues(this.values,(v)=> {
                if(Array.isArray(v)) return v.map(getFormValues)
                return getFormValues(v)
            })},
            enumerable: false
        })

        return target;
    }

    constructor(data){

        const {schema} = new.target;
        const values = {}

        Object.entries(schema).forEach(([key,[fieldType,fieldOptions = {}]])=> {
            const {nullable = true,writable = true} = fieldOptions;

            Object.defineProperty(values,key,{
                value: nullable && isNil(data[key]) ? data[key] : new fieldType(data[key],fieldOptions),
                writable: writable,
                enumerable: true
            })

        })

        Object.defineProperty(this,'values',{
            value: values,
            writable: false,
            enumerable: true,
            configurable: false
        })



    }
}