import React, {Fragment, useEffect} from "react";
import {routeParameter} from "../core/constants/routes";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getRideProvider, selectRideProviderById} from "../core/redux/slices/rideProviderSlice";
import {ButtonGroup, ContentContainer, PageHeader, PageSection} from "@odm/ui";
import i18n from "../core/constants/i18n";
import RideProviderCustomizationSection from "../forms/RideProviderCustomizationSection";
import PageInformationForm from "../forms/PageInformationForm";
import {WhitePortal} from "react-native-portal";
import portalNames from "../core/constants/portalNames";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import ScrollableContent from "../components/ScrollableContent";
import NarrowContainer from "../components/NarrowContainer";

const RideProviderCustomizationPage = () => {

  const {[routeParameter.rideProviderId]: rideProviderId} = useParams();
  const rideProvider = useSelector((state) => selectRideProviderById(state, rideProviderId));

  const [dispatchGetRideProvider] = useDispatchAsync(getRideProvider)

  useEffect(() => {
    dispatchGetRideProvider({rideProviderId});
  }, [rideProviderId]);

  return <Fragment>
    <PageHeader title={i18n.t("page customization")}
                actions={
                  <ButtonGroup>
                    <WhitePortal name={portalNames.headerBarAction}/>
                  </ButtonGroup>
                }
    />
    <ScrollableContent>
      <ContentContainer>
        <PageSection id={'customization'} title={i18n.t("customization")}>
          <NarrowContainer>
            <RideProviderCustomizationSection rideProvider={rideProvider}/>
          </NarrowContainer>
        </PageSection>

        <NarrowContainer>
          <PageInformationForm rideProvider={rideProvider}/>
        </NarrowContainer>

      </ContentContainer>
    </ScrollableContent>
  </Fragment>
}

export default RideProviderCustomizationPage;