import globalErrorSlice from '../redux/slices/globalErrorSlice';

export const errorMiddleware = (store) => (next) => (action) => {
  const data  = action?.payload?.data;
  if (data?.status === 403) {
    if (data.error === "Forbidden") {
      store.dispatch(globalErrorSlice.actions.set({ message: "Forbidden" }));
    }
    if (data.error === "Unauthorized") {
      store.dispatch(globalErrorSlice.actions.set({ message: "Unauthorized" }));
    }
  }
  return next(action);
};
