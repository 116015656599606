import {Entity} from "./Entity";

class CmRideType extends Entity {
    static selectId = (cmRideType) => cmRideType.number
    static schema = {
        number:      [String],
        description: [String],
        locked:      [Boolean]
    }

    constructor(data) {
        super(data);
    }
}

export default Entity.applySchema(CmRideType)