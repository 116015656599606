import http from "../http/http";

export const RESPONSE_STRUCTURES = {
    default: "default",
    page: "page"
};

const responseTransformers = {
    [RESPONSE_STRUCTURES.default]: (res) => res.data,
    [RESPONSE_STRUCTURES.page]: (res) => res.data.content
};

export class HttpController {
    constructor(url) {
        this.controllerUrl = url;
    }

    async post(endpoint, payload, { responseStructure = RESPONSE_STRUCTURES.default, ...config } = {}) {
        try {
            const res = await http.post(this.controllerUrl + endpoint, payload, config);
            return responseTransformers[responseStructure](res);
        } catch (error) {
            if (error.response) {
                return config.rejectWithValue(error.response);
            }
        }
    }
}
