import {Enum} from "./Enum";

export class UserRole extends Enum {
    static SUPER_ADMIN = Symbol("super-admin");
    static ADMIN = Symbol("admin");
    static USER = Symbol("user");

    constructor (state){
        super(state)
    }
}