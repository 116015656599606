import React, {useRef, useState} from "react";
import {
  CONTROL_APPEARANCE,
  ControlDecorator, ControlPreviewWrapper,
  ControlSuggestions,
  DynamicGrid,
  DynamicRow,
  DynamicRowElement,
  IconContainer,
  Input,
  Select
} from "@odm/ui";
import {Field, getIn, useFormikContext} from "formik";
import i18n from "../core/constants/i18n";
import countryCodes from "../core/constants/countryCodes";
import {defaultsDeep, isEmpty, isNil} from "lodash";
import Place from "../core/models/Place";
import {MdPlace} from "react-icons/md";

const countryOptions = [
  {
    value: countryCodes.GERMANY,
    label: i18n.t("germany")
  },
  {
    value: countryCodes.AUSTRIA,
    label: i18n.t("austria")
  }
]

const PlaceFormSection = ({prefix = ""}) => {
  const { setFieldValue, touched, errors, values} = useFormikContext();
  const autoCompleteService = useRef(new window.google.maps.places.AutocompleteService());
  const placeService = useRef(new window.google.maps.places.PlacesService(document.createElement('div')))
  const [zipSuggestions, setZipSuggestions] = useState([]);
  const [streetSuggestions, setStreetSuggestions] = useState([]);
  const [citySuggestions, setCitySuggestions] = useState([]);
  const [preview, setPreview] = useState(null);

  const fieldNames = {
    street:  [prefix,"address","street"].join("."),
    zip:     [prefix,"address","zip"].join("."),
    city:    [prefix,"address","city"].join("."),
    country: [prefix,"address","country"].join("."),
  }

  const buildSuggestionTitle = (prediction) => {
    const {main_text_matched_substrings,main_text} = prediction.structured_formatting;

    if(isEmpty(main_text_matched_substrings)) return main_text;

    const elements = [];

    elements.push(main_text.substring(0,main_text_matched_substrings[0].offset));

    main_text_matched_substrings.forEach(({offset,length},index,matches)=>{

      elements.push(<mark>{main_text.substring(offset,offset+length)}</mark>);

      if(index + 1 !== matches.length){
        elements.push(main_text.substring(offset + length,matches[index+1].offset));
      } else {
        elements.push(main_text.substring(offset + length,main_text.length));
      }
    });
    return elements;
  };

  const updatePreview = async (placeId) => {
    if(!placeId) {
      setPreview(null);
      return;
    }

    const placeDetails = await getPlaceDetails(placeId);
    const place = Place.fromPlaceDetails(placeDetails);

    if(place){
      setPreview(place);
    }
  };

  const applyPreview = async (placeId) => {
    const placeDetails = await getPlaceDetails(placeId);
    const place = Place.fromPlaceDetails(placeDetails);
    setFieldValue(prefix,defaultsDeep(place,getIn(values, prefix)));
    setPreview(null);
  };
  const buildSuggestions = (predictions) => {
    if(isNil(predictions)) return [];

    return predictions.map((prediction)=>({
      title: buildSuggestionTitle(prediction),
      description: prediction.structured_formatting.secondary_text,
      id: prediction.place_id,
      value: prediction.place_id,
      leadingSlot: <IconContainer icon={<MdPlace/>}/>,
      onSelect: applyPreview,
      onActive: updatePreview
    }))
  };

  const buildPreviewValue = (path) => {
    const value = getIn(preview,path);
    if(value) return {value};
    else return null;
  };
  const getPredictionsOfType = (input,types) => {
    return new Promise((resolve) => autoCompleteService.current.getPlacePredictions({
        input,
        types,
        componentRestrictions: {
          country: ["de"]
        }
      },(predictions)=>resolve(predictions)
    ));
  };
  const getPlaceDetails = (placeId) => {
    const request = {
      placeId,
      fields: ["name", "place_id", "address_components", "geometry"],
    };

    return new Promise((resolve,reject) => {
      placeService.current.getDetails(request, (result, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && !isNil(result)) {
          resolve(result);
        }else{
          reject();
        }
      });
    });
  };
  const getZipPredictions = async (e) => {
    const predictions = await getPredictionsOfType(e.target.value, ["postal_code"]);
    const suggestions = buildSuggestions(predictions);
    setZipSuggestions(suggestions);
  };

  const getStreetPredictions = async (e) => {
    const predictions = await getPredictionsOfType(e.target.value, ["address"]);
    const suggestions = buildSuggestions(predictions);
    setStreetSuggestions(suggestions);
  };
  const getCityPredictions = async (e) => {
    const predictions = await getPredictionsOfType(e.target.value, ["(cities)"]);
    const suggestions = buildSuggestions(predictions);
    setCitySuggestions(suggestions);
  };


  return <fieldset>
    <DynamicGrid numCols={3}>
      <DynamicRow>
        <DynamicRowElement colSpan={3}>
          <ControlDecorator label={i18n.t("street and number")}
                            labelFor={fieldNames.street}
                            error={getIn(errors,fieldNames.street)}
          >
              <ControlSuggestions suggestions={streetSuggestions}
                                  onBlur={()=>updatePreview(null)}
              >
                <ControlPreviewWrapper preview={buildPreviewValue("street")}>
                  <Field as={Input}
                         name={fieldNames.street}
                         id="input_street-and-number"
                         invalid={getIn(errors,`${prefix}.address.street`)}
                         onKeyUp={getStreetPredictions}
                         placeholder={i18n.t("street")}
                         controlAppearance={CONTROL_APPEARANCE.filled}
                  />
                </ControlPreviewWrapper>
              </ControlSuggestions>
          </ControlDecorator>
        </DynamicRowElement>
      </DynamicRow>
      <DynamicRow>
        <DynamicRowElement colSpan={1}>
          <ControlDecorator label={i18n.t("zip")}
                            labelFor={fieldNames.zip}
                            error={getIn(errors,fieldNames.zip)}
          >
            <ControlSuggestions suggestions={zipSuggestions}
                                onBlur={()=>updatePreview(null)}
            >
              <ControlPreviewWrapper preview={buildPreviewValue("zip")}>
                <Field as={Input}
                       name={fieldNames.zip}
                       id="input_zip"
                       invalid={getIn(errors,`${prefix}.address.zip`)}
                       onKeyUp={getZipPredictions}
                       placeholder={i18n.t("zip")}
                       controlAppearance={CONTROL_APPEARANCE.filled}
                />
              </ControlPreviewWrapper>
            </ControlSuggestions>
          </ControlDecorator>
        </DynamicRowElement>
        <DynamicRowElement colSpan={1}>
          <ControlDecorator label={i18n.t("city")}
                            labelFor={fieldNames.city}
                            error={getIn(errors,fieldNames.city)}
          >
            <ControlSuggestions suggestions={citySuggestions}
                                onBlur={()=>updatePreview(null)}
            >
              <ControlPreviewWrapper preview={buildPreviewValue("city")}>
                <Field as={Input}
                       name={fieldNames.city}
                       id="input_city"
                       invalid={!!getIn(errors,`${prefix}.address.city`)}
                       onKeyUp={getCityPredictions}
                       placeholder={i18n.t("city")}
                       controlAppearance={CONTROL_APPEARANCE.filled}
                />
              </ControlPreviewWrapper>
            </ControlSuggestions>
          </ControlDecorator>
        </DynamicRowElement>
        <DynamicRowElement colSpan={1}>
          <ControlDecorator label={i18n.t("country")}
                            labelFor={fieldNames.country}
                            error={getIn(errors,fieldNames.country)}
          >

            <Select name={fieldNames.country}
                    id="select_country"
                    options={countryOptions}
                    onChange={(option)=>setFieldValue(fieldNames.country,option.value)}
                    value={countryOptions.find(option=> option.value === getIn(values,fieldNames.country))}

            />
          </ControlDecorator>
        </DynamicRowElement>
      </DynamicRow>
    </DynamicGrid>
  </fieldset>

}

export default PlaceFormSection;