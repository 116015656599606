import {useState} from "react";
import {BodyText, Button, BUTTON_EMPHASIS, BUTTON_VARIANTS, InlineError, Modal, Input} from "@odm/ui";
import i18n from "../core/constants/i18n";
import {dissolve} from "../core/redux/slices/rideProviderSlice";
import useDispatchAsync from "../core/hooks/useDispatchAsync";
import {toast} from "react-toastify";

const RideProviderDissolveModal = ({rideProvider, isOpen, onClose, onDissolved}) => {
    const [inputValue, setInputValue] = useState('');
    const handleChange = (event) => {
        setInputValue(event.target.value);
    };
    const handleClose = () => {
        setInputValue('')
        onClose()
    }

    const [dispatchDissolve,error] = useDispatchAsync(dissolve);
    const handleDissolveRideProvider = () => {
        return dispatchDissolve({
            rideProviderId: rideProvider.id
        }).then(() => {
          toast.success(i18n.t("remove ride provider successful"));
          onClose();
          if (!!onDissolved) onDissolved();
        })
    }

    return (
        <Modal isOpen={isOpen}
               onClose={handleClose}
               title={i18n.t("dissolve rideProvider")}
               actionsSlot={
                <>
                   <Button title={i18n.t("cancel")}
                           id="button_cancel"
                           variant={BUTTON_VARIANTS.cancel}
                           emphasis={BUTTON_EMPHASIS.low}
                           onClick={handleClose}
                   />
                   <Button title={i18n.t("dissolve rideProvider")}
                           id="button_delete"
                           variant={BUTTON_VARIANTS.delete}
                           emphasis={BUTTON_EMPHASIS.high}
                           disabled={inputValue !== rideProvider?.companyName}
                           onClick={handleDissolveRideProvider}
                   />
                </>
               }

        >
            <BodyText>{i18n.t("confirm dissolve rideProvider",{rideProvider: `${rideProvider?.companyName}`})}</BodyText>
            <Input placeholder={rideProvider?.companyName} id="input_confirm-dissolve-ride-provider" value={inputValue} onChange={handleChange} />
            <InlineError errorMessage={!!error && i18n.t("rideProvider dissolve rejected")}/>
        </Modal>
    )
}
export default RideProviderDissolveModal;