import React from "react";
import i18n from "../core/constants/i18n";
import { Checkbox } from "@odm/ui";
import { Field, useFormikContext } from "formik";

const CustomScheduledTripsFormSection = () => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <fieldset>
      <Field
        as={Checkbox}
        label={i18n.t("pickup time")}
        id="checkbox_pickup-time-type-allowed"
        onChange={(event) => {
          setFieldValue("pickupTimeTypeAllowed", event.target.checked);
        }}
        name={"pickup-time-type-allowed"}
        checked={values.pickupTimeTypeAllowed}
        disabled={!values.arrivalTimeTypeAllowed}
      />
      <Field
        as={Checkbox}
        label={i18n.t("arrival time")}
        id="checkbox_arrival-time-type-allowed"
        onChange={(event) => {
          setFieldValue("arrivalTimeTypeAllowed", event.target.checked);
        }}
        name={"arrival-time-type-allowed"}
        checked={values.arrivalTimeTypeAllowed}
        disabled={!values.pickupTimeTypeAllowed}
      />
    </fieldset>
  );
};

export default CustomScheduledTripsFormSection;
