import {EntitySliceHelper} from "../../util/EntitySliceHelper";
import RideProvider from "../../models/RideProvider";
import rideProviderController from "../../http/controller/riderProviderController";
import {createSelector} from "@reduxjs/toolkit";
import {RideProviderState} from "../../models/RideProviderState";

const helper = new EntitySliceHelper("rideProvider", RideProvider);

export const {  selectAll: selectRideProviderAll, selectById: selectRideProviderById }= helper.getSelectors();

export const selectRideProvidersUndissolved = createSelector(selectRideProviderAll,(rideProviders)=> (
    rideProviders.filter((rideProvider)=>rideProvider.rideProviderState.value !== RideProviderState.DISSOLVED))
)

export const getRideProviders = helper.createAsyncAction(rideProviderController.getRideProviders);
export const getRideProvider = helper.createAsyncAction(rideProviderController.getRideProvider);
export const edit = helper.createAsyncAction(rideProviderController.edit);
export const dissolve = helper.createAsyncAction(rideProviderController.dissolve);
export const add = helper.createAsyncAction(rideProviderController.add);
export const editPageInformation = helper.createAsyncAction(rideProviderController.editPageInformation);

const rideProviderSlice = helper.createSlice({

    extraReducers: builder => {
        builder.addCase(getRideProviders.fulfilled, (state,action)=>{
            helper.adapter.setAll(state,action.payload)
        })
        builder.addCase(getRideProvider.fulfilled, (state,action)=>{
            helper.adapter.upsertOne(state,action.payload)
        })
        builder.addCase(edit.fulfilled, (state,action)=>{
            helper.adapter.upsertOne(state,action.payload)
        })
        builder.addCase(dissolve.fulfilled, (state,action)=>{
            helper.adapter.upsertOne(state,action.payload)
        })
        builder.addCase(add.fulfilled, (state, action)=>{
            helper.adapter.upsertOne(state,action.payload)
        })
        builder.addCase(editPageInformation.fulfilled, (state, action)=>{
            helper.adapter.upsertOne(state,action.payload)
        })
    }
})

export default rideProviderSlice;