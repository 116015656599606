import React from "react";
import {
  Checkbox,
  CONTROL_DECORATOR_CONTROL_HEIGHTS,
  ControlDecorator,
  Textarea,
  TransitionContainer
} from "@odm/ui";
import {Field, getIn, useFormikContext} from "formik";
import i18n from "../core/constants/i18n";
import {BiSolidVirus} from "react-icons/bi";
import {VscBlank} from "react-icons/vsc";

const InfectionFormSection = () => {
  const {values} = useFormikContext();
  const isInfectious = getIn(values, "infectionInformation.infectious");

  return <fieldset>
    <ControlDecorator icon={<BiSolidVirus/>}>
      <Field as={Checkbox}
             id="checkbox_infection-information-infectious"
             name={"infectionInformation.infectious"}
             label={i18n.t("infectious")}
             checked={getIn(values,"infectionInformation.infectious")}
      />
    </ControlDecorator>
    <TransitionContainer show={isInfectious} transition={"height"}>
      <ControlDecorator icon={<VscBlank/>} controlHeight={CONTROL_DECORATOR_CONTROL_HEIGHTS.auto}>
        <Field id="textarea_infection-note" as={Textarea} name={"infectionInformation.infectionNote"} placeholder={i18n.t("infection note placeholder")}/>
      </ControlDecorator>
    </TransitionContainer>
  </fieldset>
}

export default InfectionFormSection;