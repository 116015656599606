import {HttpController, RESPONSE_STRUCTURES} from "../../util/HttpController";

const controller = new HttpController("/api/provider/");

const rideProviderController = {
    getRideTypes:           (payload) => controller.post('getRideTypes', {...payload}),
    getRideProviders:       (payload) => controller.post('getRideProviders', {...payload},{responseStructure: RESPONSE_STRUCTURES.page}),
    getRideProvider:        (payload) => controller.post('getRideProvider',{...payload}),
    edit:                   (payload) => controller.post('edit', {...payload}),
    dissolve:               (payload) => controller.post('dissolve', {...payload}),
    add:                    (payload) => controller.post('add', {...payload}),
    getPageInformation:     (payload) => controller.post('getPageInformation', {...payload}),
    editPageInformation:    (payload) => controller.post('editPageInformation', {...payload}),
}

export default rideProviderController;