import {createAsyncThunk, createEntityAdapter, createSelector, createSlice} from "@reduxjs/toolkit";
import {mapValues} from "lodash";

export class EntitySliceHelper {
    constructor(name,entityClass){
        this.name = name;

        this.sliceSelector = (state) => state[name];

        this.baseSelector = createSelector(this.sliceSelector, ({ids, entities}) =>
            ({
                ids,
                entities: mapValues(entities, entity=>new entityClass(entity))
            })
        )

        this.adapter = createEntityAdapter({
            selectId: entityClass.selectId
        })

        this.initialState = this.adapter.getInitialState()
    }

    createAsyncAction(fn){
        return createAsyncThunk(`${this.name}/${fn.name}`,fn)
    }

    createSlice(sliceOptions){

        if(sliceOptions.initialState){
            this.initialState = sliceOptions.initialState
        }

        return createSlice({
            name: this.name,
            initialState: this.initialState,
            reducers: {},
            ...sliceOptions
        })
    }

    getSelectors(){
        return this.adapter.getSelectors(this.baseSelector)
    }
}