import {ContentContainer, EmptyState, PageHeader} from "@odm/ui";
import i18n from "../core/constants/i18n";
import React from "react";
import {MdBlock} from "react-icons/md";
import {Link} from "react-router-dom";

const NotFoundPage = () => {
    return (
        <>
            <PageHeader title={i18n.t("page not found")} />
            <ContentContainer>
                <EmptyState title={i18n.t("not existing page")}
                            description={i18n.t("visit home page")}
                            icon={<MdBlock />}
                            actions={
                                <Link to={'/'}>
                                    Home
                                </Link>
                            }
                />
            </ContentContainer>
        </>
    )
}

export default NotFoundPage